import { useParams } from 'react-router'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/dashboard'
import useInterval from 'use-interval'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import TopResults from './sections/topResultsSection/TopResultsSection'

import styles from './Multisector.module.scss'
import Spinner from 'components/spinner/Spinner'
import { MultisectorData } from 'store/dashboard/types'
import MultisectorTableSection from './sections/MultisectorTableSection'
import SectorSection from './sections/SectorSection'
import InformationSection from './sections/InformationSection'
import { Avatar, Box, Collapse, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import FiltersSection from './sections/FiltersSection'

type ParamsType = {
  id: string
}

export default function Multisector() {
  const dispatch = useAppDispatch()
  const status = useSelector((state: RootState) => state.dashboard.multisectorStatus)
  const data: MultisectorData | null = useSelector((state: RootState) => state.dashboard.multisectorData)
  const multisectorLoading = useSelector((state: RootState) => state.dashboard.loading.multisectorData)

  const thirdPartyDataList = useSelector((state: RootState) => state.dashboard.multisectorThirdPartyDataList)
  const errorDashboard = useSelector((state: RootState) => state.dashboard.error)

  const [delay, setDelay] = useState<number>(6000)

  const [showMultisectorTable, setShowMultisectorTable] = useState(false)
  // subgroupsSorted contains the same subgroups that appear in info.subgroups, but this way we get them in order of appearance.
  const subgroupsSorted = Array.from(new Set(data?.multisector.slice(0, 1000).map(item => item.subgroup)))
  const [subgroupsSelected, setSubgroupsSelected] = useState<string[]>([])
  const params = useParams<ParamsType>()
  const id = params?.id || ''

  const stopPolling = Boolean(
    errorDashboard.multisectorStatus ||
      (status &&
        ['regenerating', 'whales_error', 'multisector_error', 'comparison_error', 'comparison_finished'].includes(status))
  )

  const regenerateMultisector = () => {
    dispatch(dashboardActions.regenerateMultisector(id))
  }

  const primaryColor = 'primary.main'
  const textSecondaryColor = 'text.secondary'

  useEffect(() => {
    dispatch(dashboardActions.getMultisectorStatus(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(dashboardActions.getMultisectorStatus(id))
    if (data) {
      dispatch(dashboardActions.fetchMultisectorThirdPartyData(id))
    }
  }, [data])

  useInterval(
    () => {
      dispatch(dashboardActions.getMultisectorStatus(id))
    },
    stopPolling ? null : delay
  )

  useEffect(() => {
    dispatch(dashboardActions.getMultisectorStatus(id))
  }, [status])

  useEffect(() => {
    if (status === 'comparison_processing') setDelay(3000)
  }, [status])

  useEffect(() => {
    if (!data && status === 'comparison_finished') {
      dispatch(dashboardActions.fetchMultisectorData(id))
    }
  }, [dispatch, id, data, status])

  return (
    <Box display="flex" flexDirection="column" sx={{ p: 3 }}>
      {Boolean((multisectorLoading || !stopPolling) && !errorDashboard.multisectorData && !errorDashboard.multisectorStatus) && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {errorDashboard.multisectorStatus && (
        <div className={styles.error}>Status error: {errorDashboard.multisectorStatus}. Refresh the page</div>
      )}
      {errorDashboard.regenerateMultisector && (
        <div className={styles.error}>Regenerate multisector error: {errorDashboard.regenerateMultisector}. </div>
      )}
      {status && status !== 'comparison_finished' && <div>Status: {status}</div>}
      {errorDashboard.multisectorData && <div className={styles.error}>{errorDashboard.multisectorData}</div>}
      {data && (
        <>
          {data.multisector?.length > 0 && (
            <Stack direction="row" spacing={2}>
              <Box component="section" sx={{ p: 2 }}>
                <Typography color={textSecondaryColor} fontSize={14}>
                  Seed Company(s)
                </Typography>
                <Typography color={primaryColor}>{data.multisector[0].applicant_original_name}</Typography>
              </Box>
              <Box component="section" sx={{ p: 2 }}>
                <Typography color={textSecondaryColor} fontSize={14}>
                  Timestamp
                </Typography>
                <Typography color={primaryColor}>{data.info.created_at}</Typography>
              </Box>
            </Stack>
          )}

          <InformationSection
            id={id}
            data={data}
            thirdPartyDataList={thirdPartyDataList}
            regenerateMultisector={regenerateMultisector}
          />

          {data.multisector?.length > 0 && thirdPartyDataList && subgroupsSorted && (
            <>
              <FiltersSection
                data={data}
                subgroups={subgroupsSorted}
                onSubgroupsSelectedChange={subgroupsSelected => setSubgroupsSelected(subgroupsSelected)}
              />

              <TopResults data={data} subgroups={subgroupsSelected} thirdPartyDataList={thirdPartyDataList} />

              {subgroupsSelected.map((subgroup, index) => (
                <SectorSection
                  index={index + 1}
                  subgroupCode={subgroup}
                  data={data}
                  thirdPartyDataList={thirdPartyDataList}
                  status={status!}
                />
              ))}

              <Avatar sx={{ bgcolor: 'primary.main' }} onClick={() => setShowMultisectorTable(!showMultisectorTable)}>
                {showMultisectorTable ? <RemoveIcon sx={{ cursor: 'pointer' }} /> : <AddIcon sx={{ cursor: 'pointer' }} />}
              </Avatar>
              <Collapse in={showMultisectorTable}>
                <MultisectorTableSection data={data} thirdPartyDataList={thirdPartyDataList} status={status!} />
                {/* {data.comparison && <ComparisonTable comparisonData={data.comparison} />} */}
              </Collapse>
            </>
          )}
        </>
      )}
    </Box>
  )
}
