import { useEffect, useState } from 'react'
import { MultisectorData } from 'store/dashboard/types'
import { Box, Checkbox, Collapse, FormControlLabel, FormGroup, Paper, Stack } from '@mui/material'

import OpenCloseButton from './components/OpenCloseButton'
import SectionContainer from './components/SectionContainer'
import SectionHeader from './components/SectionHeader'
import SectionSubTitle from './components/SectionSubtitle'

type Props = {
  data: MultisectorData
  subgroups: string[]
  onSubgroupsSelectedChange: (selectedSubgroups: string[]) => void
}

export default function FiltersSection({ data, subgroups, onSubgroupsSelectedChange }: Props) {
  const [sectionOpened, setSectionOpened] = useState(false)
  const [subgroupsSelected, setSubgroupsSelected] = useState<string[]>(subgroups)

  useEffect(() => {
    onSubgroupsSelectedChange(subgroups)
  }, [])

  // Función para manejar el cambio de los checkboxes
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target

    setSubgroupsSelected(prevSelected => {
      const newSelected = checked ? [...prevSelected, value] : prevSelected.filter(item => item !== value)

      // Notificar al componente padre del cambio
      onSubgroupsSelectedChange(newSelected)

      return newSelected
    })
  }
  return (
    <SectionContainer>
      <SectionHeader title={data.multisector[0].applicant_original_name}>
        <Stack direction="row" alignItems="center">
          <OpenCloseButton onClick={() => setSectionOpened(!sectionOpened)} opened={sectionOpened} />
        </Stack>
      </SectionHeader>
      <Collapse in={sectionOpened}>
        <Paper variant="outlined" sx={{ borderRadius: '20px', m: 3, mt: 0 }}>
          <SectionSubTitle title="Sectors" />
          <Box px={3} pb={2}>
            <FormGroup>
              {subgroups.map(subgroupCode => {
                const subgroupInfo = data.info.subgroups_info.find(sub => sub.code === subgroupCode)
                const subgroupTitle = subgroupInfo?.claude_main_title || subgroupCode
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={subgroupCode}
                        checked={subgroupsSelected.includes(subgroupCode)}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>{subgroupTitle}</span>}
                  />
                )
              })}
            </FormGroup>
          </Box>
        </Paper>
      </Collapse>
    </SectionContainer>
  )
}
