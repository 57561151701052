import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as accountActions } from 'store/account'

import { ReactComponent as Logo } from 'assets/images/insights-IDS.svg'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Divider, IconButton, Menu, styled, Toolbar, Tooltip, Typography } from '@mui/material'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}))
function Header() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const iconsRef = useRef<HTMLDivElement>(null)
  const firstName = useSelector((state: RootState) => state.account.firstName)

  const logOut = (ev: React.MouseEvent) => {
    dispatch(accountActions.logout())
    history(`/`)
  }

  const UserMenu = () => {
    return (
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        <Link to={'/settings/security'} style={{ textDecoration: 'none', color: 'black' }}>
          <MenuItem key={'settings'} onClick={handleCloseUserMenu}>
            <Typography sx={{ textAlign: 'center' }}>Settings</Typography>
          </MenuItem>
        </Link>

        <Divider />
        <MenuItem key={'logout'} onClick={e => logOut(e)}>
          <Typography sx={{ textAlign: 'center' }}>Log out</Typography>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <AppBar position="static" sx={{ height: '65px', backgroundColor: 'white' }}>
      <Toolbar>
        <Stack justifyContent="space-between" width={'100%'} direction="row" alignItems="center">
          <Box sx={{ p: 2.5 }}>
            {/* <Link to="/">
              <Logo width={190} />
            </Link> */}
          </Box>
          <Stack
            ref={iconsRef}
            direction="row"
            alignItems="space-between"
            spacing={2}
            sx={{ backgroundColor: 'white', marginRight: '20px' }}>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={firstName} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <UserMenu />
            </Box>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Header
