import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import rootReducer from './rootReducer'
import { createInjectorsEnhancer } from 'redux-injectors'
import rootSaga from './rootSaga'
import '@fontsource/inter' // Defaults to weight 400
import '@fontsource/inter/400.css' // Specify weight
import createSagaMiddleware from '@redux-saga/core'

const sagaMiddleware = createSagaMiddleware()
const { run: runSaga } = sagaMiddleware

const enhancers = [
  createInjectorsEnhancer({
    createReducer: () => rootReducer,
    runSaga,
  }),
]

//createStore eq
export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  preloadedState: {},
  devTools: process.env.NODE_ENV !== 'production',
  enhancers,
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store
