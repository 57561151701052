import * as React from 'react'
import { useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Applicant, MultisectorData, ThirdPartyData } from 'store/dashboard/types'
import { MultisectorStatus } from 'store/dashboard'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import SectionContainer from './components/SectionContainer'
import SectionHeader from './components/SectionHeader'

type Props = {
  data: MultisectorData
  thirdPartyDataList: ThirdPartyData[] | null
  status: MultisectorStatus
}

interface Column {
  id: keyof Applicant
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: any) => any
}
interface ThirdPartyDataColumn {
  id: keyof ThirdPartyData
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: any) => any
}

export default function MultisectorTableSection({ data, thirdPartyDataList, status }: Props) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getKey = (app: Applicant) => 'tab' + app.applicant_original_name + app.comparison_score + app.subgroup

  const whalesData = (whalesData: number) => {
    const data = whalesData ? Number(whalesData.toFixed(3)) : whalesData
    return <>{data}</>
  }

  const comparisonScoreData = (value: any) => {
    return (
      <>
        {status === 'comparison_error' ? (
          status
        ) : status === 'comparison_processing' ? (
          <CircularProgress size={12} color="inherit" />
        ) : value && typeof value != 'string' ? (
          Number(value.toFixed(2))
        ) : (
          value
        )}
      </>
    )
  }

  const columns: Column[] = [
    { id: 'pos', label: '#' },
    {
      id: 'research',
      label: 'Research',
      format: (value: boolean) => (value ? 'Research' : ''),
    },
    { id: 'applicant_original_name', label: 'Applicant', minWidth: 100 },
    { id: 'subgroup', label: 'Subgroup' },
    {
      id: 'intersection_score_sum',
      label: 'Intersection score sum',
      align: 'right',
      format: (value: number) => value.toFixed(2),
    },

    {
      id: 'intersection_score_sum_normalized',
      label: 'Intersection score sum normalized',
      format: (value: number) => value.toFixed(2),
    },
    { id: 'count_in_subgroup', label: 'Count in subgroup' },
    { id: 'count_in_subgroup_normalized', label: 'Count in subgroup normalized', format: (value: number) => value.toFixed(2) },
    { id: 'whales_categories_count', label: 'Whales categories count' },
    {
      id: 'whales_categories_count_normalized',
      label: 'Whales categories count normalized',
      format: (value: number) => value.toFixed(2),
    },
    { id: 'whales_patent_count', label: 'whales patent count', format: (value: any) => whalesData(value) },
    { id: 'whales_patent_in_percent', label: 'whales patent in percent', format: (value: number) => whalesData(value) },
    { id: 'whales_patent_out_categories', label: 'whales patent out categories', format: (value: number) => whalesData(value) },
    { id: 'whales_patents_in_categories', label: 'whales patents in categories', format: (value: number) => whalesData(value) },
    { id: 'comparison_score', label: 'Comparison score', format: (value: any) => comparisonScoreData(value) },
    { id: 'comparison_score_normalized', label: 'Comparison score normalized', format: (value: any) => value?.toFixed(2) },
  ]

  const thirdPartyDataColumns: ThirdPartyDataColumn[] = [{ id: 'normalized_url', label: 'Owler normalized_url' }]

  return (
    <SectionContainer>
      <SectionHeader title="Multisector"></SectionHeader>
      <Paper sx={{ borderRadius: '20px', margin: '20px' }}>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
                {thirdPartyDataColumns.map(tpdColumn => (
                  <TableCell key={tpdColumn.id} align={tpdColumn.align} style={{ minWidth: tpdColumn.minWidth }}>
                    {tpdColumn.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.multisector.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((applicant, index) => {
                const newIndex = index + 1 + page * rowsPerPage
                applicant = { ...applicant, pos: newIndex }

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={getKey(applicant)}>
                    {columns.map(column => {
                      const value = applicant[column.id]
                      return (
                        <TableCell key={column.id} align={column.align} sx={{ padding: '6px 8px' }}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                    {thirdPartyDataList &&
                      thirdPartyDataColumns.map(tpdColumn => {
                        const tpd = thirdPartyDataList.filter(tpd => tpd.applicant_cleaned === applicant.applicant_cleaned)[0]
                        const value = tpd && tpd[tpdColumn.id]

                        thirdPartyDataList?.map(
                          data =>
                            applicant.applicant_cleaned === data.applicant_cleaned && (
                              <TableCell key={tpdColumn.id} align={tpdColumn.align} style={{ minWidth: tpdColumn.minWidth }}>
                                {tpdColumn.label}
                              </TableCell>
                            )
                        )

                        return (
                          <TableCell key={tpdColumn.id} align={tpdColumn.align} style={{ minWidth: tpdColumn.minWidth }}>
                            {value}
                          </TableCell>
                        )
                      })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[15, 30, 100]}
          component="div"
          count={data.multisector.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </SectionContainer>
  )
}
