import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { IoCaretBackOutline as BackArrow } from 'react-icons/io5'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import styles from './UserSearchesPage.module.scss'
import SearchesList from 'containers/tables/searchesList/SearchesList'
import Title from 'components/title/Title'

const UserSearchesPage = () => {
  const { id } = useParams<string>()
  const dispatch = useAppDispatch()
  const userDetails = useSelector((state: RootState) => state.adminArea.userDetails)
  const user = userDetails.id === id ? userDetails.user : null

  useEffect(() => {
    if (id) {
      dispatch(dashboardActions.getUser(id))
    }
  }, [dispatch, id])

  return (
    <>
      {id && user && (
        <div className={styles.base}>
          <div className={styles.header}>
            <span className={styles.goback}>
              <Link to="/settings/admin">
                <BackArrow size="1.1em" />
                Back
              </Link>
            </span>
            <Title variant="h4">
              Searches for {user.firstName} {user.lastName}
            </Title>
          </div>
          <SearchesList userId={id} />
        </div>
      )}
    </>
  )
}

export default UserSearchesPage
