import { useState } from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/dashboard'
import { MultisectorData, ThirdPartyData } from 'store/dashboard/types'
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import DownloadIcon from '@mui/icons-material/Download'
import Spinner from 'components/spinner/Spinner'
import OpenCloseButton from './components/OpenCloseButton'
import SectionContainer from './components/SectionContainer'
import SectionHeader from './components/SectionHeader'
import SectionSubTitle from './components/SectionSubtitle'

type Props = {
  id: string
  data: MultisectorData | null
  thirdPartyDataList: ThirdPartyData[] | null
  regenerateMultisector: () => void
}

export default function InformationSection({ id, data, thirdPartyDataList, regenerateMultisector }: Props) {
  const dispatch = useAppDispatch()
  const zipIsLoading = useSelector((state: RootState) => state.dashboard.loading.multisectorZip) //TODO
  const roles = useSelector((state: RootState) => state.account.roles)
  const [sectionOpened, setSectionOpened] = useState(false)
  const [showSearches, setShowSearches] = useState(false)
  const [showThirdPartyJson, setShowThirdPartyJson] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportZip = () => {
    dispatch(dashboardActions.exportMultisectorZip(id))
  }

  return (
    <SectionContainer>
      <SectionHeader title="Seed Company(s) search information" titleSize="18">
        <Stack direction="row" alignItems="center">
          <Button
            id="action-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ mr: 4 }}>
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'action-button',
            }}>
            <MenuItem onClick={regenerateMultisector}>
              <Avatar
                sx={{ bgcolor: 'primary.main', mr: 1, width: 25, height: 25 }}
                onClick={() => setSectionOpened(!sectionOpened)}>
                <AutorenewIcon sx={{ cursor: 'pointer', width: 17 }} />
              </Avatar>
              Regenerate Multisector
            </MenuItem>
            {data && (
              <>
                <MenuItem onClick={exportZip}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 25, height: 25 }} onClick={exportZip}>
                    <DownloadIcon sx={{ cursor: 'pointer', width: 17 }} />
                  </Avatar>
                  Export zip{zipIsLoading && <Spinner />}
                </MenuItem>
                {roles?.includes('bo_admin') && (
                  <MenuItem onClick={() => setShowSearches(!showSearches)}>
                    {showSearches ? <>Hide searches</> : <>Show selected searches </>}
                  </MenuItem>
                )}
              </>
            )}
            {roles?.includes('bo_admin') && (
              <MenuItem onClick={() => setShowThirdPartyJson(!showThirdPartyJson)}>
                {showThirdPartyJson ? <>Hide third party data</> : <>Show third party json</>}
              </MenuItem>
            )}
          </Menu>
          <OpenCloseButton onClick={() => setSectionOpened(!sectionOpened)} opened={sectionOpened} />
        </Stack>
      </SectionHeader>
      <Collapse in={sectionOpened}>
        <Paper variant="outlined" sx={{ borderRadius: '20px', m: 3, mt: 0 }}>
          <SectionSubTitle title="Search dimensions" />

          <Divider />
          {data && (
            <>
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell key={1}>Sectors</TableCell>
                      <TableCell key={2}>Search limit</TableCell>
                      <TableCell key={3}>Start date </TableCell>
                      <TableCell key={3}>End date</TableCell>
                      <TableCell key={4}>Patent searches</TableCell>
                      <TableCell key={5}>Report length</TableCell>
                      <TableCell key={6}>Only active patents</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell key={1}>{data.info.subgroups.join(', ')}</TableCell>
                      <TableCell key={2}>{data.info.search_limit}</TableCell>
                      <TableCell key={3}>{data.info.start_date}</TableCell>
                      <TableCell key={3}>{data.info.end_date}</TableCell>
                      <TableCell key={4}>{data.info.searches_selected_length}</TableCell>
                      <TableCell key={5}>{data.multisector.length}</TableCell>
                      <TableCell key={6}>{String(Boolean(data.info.only_protected_patents))}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {showSearches && (
                <Box m={2}>
                  <Typography color={'primary.main'} fontSize={16}>
                    Searches selected:
                  </Typography>

                  <List>
                    {data.info.searches_selected.map(search => (
                      <ListItem disablePadding> - {search.name}</ListItem>
                    ))}
                  </List>
                </Box>
              )}

              {showThirdPartyJson && thirdPartyDataList && (
                <Box m={2}>
                  <Typography color={'primary.main'} fontSize={16}>
                    Third party data:
                  </Typography>
                  <List>
                    {thirdPartyDataList.map(tp => (
                      <ListItem disablePadding sx={{ py: 1 }}>
                        {JSON.stringify(tp)}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </>
          )}
          <Stack direction="row" justifyContent="space-between"></Stack>
        </Paper>
      </Collapse>
    </SectionContainer>
  )
}
