import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import { TextField, Button, CircularProgress, Typography } from '@mui/material'

type Props = {
  userId: string
  searchesSelected: string[]
}

export default function ExportMultipleResultsExcel({ userId, searchesSelected }: Props) {
  const dispatch = useAppDispatch()
  const isExportMultipleResultsLoading = useSelector((state: RootState) => state.adminArea.loading.multipleResults)
  const [limit, setLimit] = useState<number>(2600)
  const multipleResultsError = useSelector((state: RootState) => state.adminArea.error.multipleResults)

  const exportMultipleResults = () => {
    dispatch(dashboardActions.exportMultipleResultsExcel({ searchesCreatedBy: userId, searchIds: searchesSelected, limit }))
  }

  return (
    <>
      <div>
        <TextField
          id="limit"
          name="limit"
          label="Limit"
          value={limit}
          sx={{ mr: 3, input: { p: 1.4, width: 60 } }}
          onChange={event => {
            setLimit(Number(event?.target.value))
          }}
        />
        {/* <label>Limit</label>
                    <input onChange={event => setLimit(Number(event?.target.value))} value={limit}></input> */}
      </div>

      <div>{isExportMultipleResultsLoading && <CircularProgress sx={{ ml: 1 }} size="20px" color="inherit" />}</div>

      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={exportMultipleResults}
        disabled={searchesSelected.length === 0 || isExportMultipleResultsLoading}>
        Export multiple results excel
      </Button>
      {multipleResultsError && <Typography color="red">{multipleResultsError}</Typography>}
    </>
  )
}
