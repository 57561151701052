import { useEffect, useState } from 'react'
import TextWordSpliterInput from '../textWordSpliterInput/TextWordSpliterInput'
import { Option } from './types'
import styles from './MultiSelect.module.scss'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  InputLabel,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material'

type Props = {
  options: Option[]
  onSelectChange: Function
  title: string
  optionName: string
  optionNamePlural: string
  hasKeywordFilter?: boolean
  onKeywordFilterChange?: Function
  forceAllExpanded?: boolean
  suggestedWords?: string[] | undefined
  onOpen?: Function
  hasSelectAllOption?: boolean
  preselectedIds?: string[]
  preselectAllOptions?: boolean
  error?: boolean
}

function MultiSelect({
  options,
  onSelectChange,
  title,
  optionName,
  optionNamePlural,
  hasKeywordFilter = false,
  onKeywordFilterChange = () => {},
  forceAllExpanded = false,
  suggestedWords,
  onOpen = () => {},
  hasSelectAllOption = true,
  preselectAllOptions = true,
  error,
}: Props) {
  const [open, setOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([])
  const [expandedParents, setExpandedParents] = useState<(string | number)[]>([])
  const [filterInputVal, setFilterInputVal] = useState<string>('')

  const numberOfOptions = () => flattenNestedIfAvailable(options).length
  const isAllSelected = () => selectedValues.length >= numberOfOptions()

  useEffect(() => {
    if (preselectAllOptions) onSelectAllClick() // addOptionsToSelected(options)
  }, [])

  // watch selected values and return them when they change
  useEffect(() => {
    onSelectChange(selectedValues)
  }, [selectedValues])

  useEffect(() => {
    if (open) onOpen()
  }, [open, onOpen])

  // Get a text for the different number of selected options
  const getInputTitle = () => {
    const count = selectedValues.length

    if (count) {
      const pluralisedOptionName = count > 1 ? optionNamePlural : optionName

      return `${selectedValues.length} ${pluralisedOptionName} selected`
    } else {
      return title
    }
  }

  const capitalizeFirstLetter = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }
  const flattenNestedIfAvailable = (opts: Option[]) => {
    let accum: (string | number)[] = []

    opts.forEach(option => {
      if (option.id && !option.children?.length) {
        accum.push(option.id)
      }

      if (option.children?.length) {
        const children = flattenNestedIfAvailable(option.children)
        accum = [...accum, ...children]
      }
    })

    return accum
  }

  // select and deselect all options
  const onSelectAllClick = () => {
    if (isAllSelected()) setSelectedValues([])
    else {
      const flattenOptions = flattenNestedIfAvailable(options)
      setSelectedValues(flattenOptions)
    }
  }

  // when option row ( or checkbox when parent) is clicked
  const onOptionClick = (clickedOption: Option) => {
    let updated = []

    if (clickedOption.children?.length) {
      const childIds = clickedOption.children.map(o => o.id)
      isSelected(clickedOption)
        ? (updated = selectedValues.filter(selected => !childIds.includes(selected)))
        : (updated = [...selectedValues, ...childIds])
    } else {
      updated = isSelected(clickedOption)
        ? selectedValues.filter(s => s !== clickedOption.id)
        : [...selectedValues, clickedOption.id]
    }

    setSelectedValues(updated)
  }

  // expand to see the child options when parent row clicked
  const expandParentClick = (op: Option) => {
    const parentindex = expandedParents.indexOf(op.id)

    const updated = parentindex > -1 ? expandedParents.filter(p => p !== op.id) : [...expandedParents, op.id]

    console.log(updated)
    setExpandedParents(updated)
  }

  // is a parent row expanded to show children
  const isExpanded = (op: Option) => expandedParents.indexOf(op.id) > -1 || forceAllExpanded

  // is a check box in the list of selcted options
  const isSelected = (op: Option) => {
    if (op.children?.length) {
      const childIds = op.children.map(o => o.id)

      let allIdsSelected = childIds.every(id => selectedValues.indexOf(id) > -1)

      return allIdsSelected
    } else {
      return selectedValues.indexOf(op.id) > -1
    }
  }

  // Markup for the option rows
  const getOptionRow = (op: Option) => {
    const isParent = op.children?.length
    return (
      <div key={`${op.id}_${op.name}`}>
        <div
          id={op.id.toString()}
          className={[styles.optionRow, isParent ? styles.isParent : '', isExpanded(op) ? styles.isOpen : ''].join(' ')}
          onClick={ev => {
            ev.stopPropagation()
            if (isParent) {
              expandParentClick(op)
            } else onOptionClick(op)
          }}>
          <FormControlLabelStyled
            sx={{ ml: 0.1 }}
            control={
              <Checkbox
                size="small"
                checked={isSelected(op)}
                onChange={() => {
                  if (isParent) {
                    onOptionClick(op)
                  }
                }}
                name={op.name}
              />
            }
            label={op.name}
          />
        </div>

        {op.children?.length && isExpanded(op) && (
          <div className={styles.optionRowChildren}>{op.children.map(opChild => getOptionRow(opChild))}</div>
        )}
      </div>
    )
  }

  const _onFilterInputChange = (val: string) => {
    setFilterInputVal(val)
    onKeywordFilterChange(val)
  }

  const onSuggestedWordClick = (word: string) => {
    let split = filterInputVal.split(' ')
    split[split.length - 1] = word
    const newVal = split.join(' ')

    _onFilterInputChange(newVal + ' ')
  }

  ///
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const FormControlLabelStyled = styled(FormControlLabel)<FormControlLabelProps>(({}) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: '13px',
    },
  }))

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-checkbox-label">{capitalizeFirstLetter(optionNamePlural)}</InputLabel>
      <Select
        error={error}
        sx={{ backgroundColor: 'white' }}
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={[getInputTitle()]}
        input={<OutlinedInput label={optionNamePlural} />}
        renderValue={selected => selected.join(', ')}
        MenuProps={MenuProps}>
        <>
          {hasKeywordFilter && open && (
            <TextWordSpliterInput filterInputVal={filterInputVal} onFilterValueChange={_onFilterInputChange} />
          )}
          <div className={styles.suggestedWordsContainer}>
            {suggestedWords?.slice(0, 5).map(s => (
              <div key={s} className={styles.suggestedWord} onClick={() => onSuggestedWordClick(s)}>
                {s}
              </div>
            ))}
          </div>
          {hasSelectAllOption && (
            <>
              <FormControlLabelStyled
                sx={{ ml: 1 }}
                control={<Checkbox checked={isAllSelected()} onChange={() => onSelectAllClick()} name="selectall" />}
                label="Select all"
              />
              <Divider />
            </>
          )}
          {options.map(op => {
            return getOptionRow(op)
          })}
        </>
      </Select>
    </FormControl>
  )
}

export default MultiSelect
