import { useSelector } from 'react-redux'
import { RootState } from 'store'

import MultisectorsList from 'containers/tables/multisectorsList/MultisectorsList'
import { Box, Typography } from '@mui/material'
import Title from 'components/title/Title'

export default function MultisectorsListPage() {
  const userId = useSelector((state: RootState) => state.account.userId)
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        p={5} // Padding 40px equals p={5} in MUI (1 = 8px)
      >
        <Title sx={{ pb: 3 }}>My multisector reports</Title>
        <MultisectorsList userId={userId} />
      </Box>
    </>
  )
}
