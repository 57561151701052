import axios, { AxiosRequestConfig, ResponseType } from 'axios'
import jwt_decode from 'jwt-decode'
import { store } from 'store'
import { actions as accountActions } from 'store/account'
import { serialize } from 'object-to-formdata'
const apiUrl = `${process.env.REACT_APP_API_V1_URL}`

type TokenType = {
  email: string
  exp: number
  iat: number
  jti: string
  userId: string
}

export const ENDPOINTS = {
  createUser: '/user',
  users: '/user',
  user: (userId: string) => `/user/${userId}`,
  updateUser: (userId: string) => `/user/${userId}`,
  userSearches: (userId: string) => `/search/user/${userId}/searches`,
  userSearchResults: (userId: string, searchId: string) => `/search/${userId}/${searchId}/results`,
  exportResults: (userId: string, searchId: string) => `/search/${userId}/${searchId}/results/export`,
  exportResultsForAnalysis: (userId: string, searchId: string) => `/search/${userId}/${searchId}/results/export/analysis`,
  exportMultipleResults: (userId: string) => `/search/${userId}/export/multipleresults`,
  companySuggestions: '/company/suggestions',
  companySearch: '/company/search',
  companySearchStatus: (searchId: string) => `/company/search/${searchId}/status`,
  sendConfirmationEmail: '/user/sendConfirmationEmail',
  login: '/user/login',
  confirmUser: '/user/confirm',
  accountRecovery: '/user/accountRecovery',
  resetPassword: '/user/reset',
  updatePassword: '/user/update',
  refreshToken: '/user/refresh',
  categories: '/resources/patent/categories',
  searches: '/search',
  singleSearch: (searchID: string) => `/search/${searchID}`,
  searchStatus: (searchID: string) => `/search/${searchID}/status`,
  reportData: (searchID: string, isPdfPrinter: Boolean) =>
    `/search/${searchID}/results/report/data${isPdfPrinter ? '?is_pdf_printer' : ''}`,
  reportPDF: (searchID: string) => `/search/${searchID}/report/pdf`,
  generateMultisector: '/search/multisector',
  multisectorStatus: (id: string) => `/search/multisector/${id}/status`,
  multisectorData: (id: string) => `/search/multisector/${id}/data`,
  multisectorZip: (id: string) => `/search/multisector/${id}/zip`,
  multisectorRegenerate: (id: string) => `/search/multisector/${id}/regenerate`,
  multisectorThirdPartyData: (id: string) => `/search/multisector/${id}/thirdPartyData`,
  regenerateMultisector: (id: string) => `/search/multisector/${id}/regenerate`,
  multisectors: (userId: string) => `/search/${userId}/multisectors`,
  ping: `/utilities/ping`,
  systemError: '/system/error',
}

/********************************************************
 * Add any headers etc needed for every call
 ********************************************************/
export function axiosConfig(params: any, headers = {}) {
  const user = {
    id: `${process.env.REACT_APP_USER_ID}`,
    token: localStorage.getItem('token'),
  }

  const config: any = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Csrf-Token': 'nocheck',
      Accept: 'application/json',
      Authorization: `Bearer ${user.token}`,
      'x-userID': user?.id,
      //   "X-localization": getLocale(),
      ...headers,
    },
    params: {},
  }

  if (params) {
    config.params = params

    return config
  }
}

export const isTokenExpired = (token: string | null) => {
  try {
    if (token) {
      const decodedToken: TokenType = jwt_decode(token!)
      const now = new Date().getTime() / 1000
      if (decodedToken.exp > now) return false
      else return true
    } else return true
  } catch (error) {
    store.dispatch(accountActions.logout())
  }
}

export const isAccessTokenExpired = () => {
  const token = localStorage.getItem('token')
  return isTokenExpired(token)
}

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')

  // Si no hay refreshToken, simplemente hacemos logout y salimos
  if (!refreshToken) {
    store.dispatch(accountActions.logout())
    return // No hacemos nada más si no hay refreshToken
  }
  return new Promise((resolve, reject) => {
    post(ENDPOINTS.refreshToken, {
      refreshToken,
    })
      .then(resp => {
        if (resp.status === 200) {
          localStorage.setItem('token', resp.data.token)
          localStorage.setItem('refreshToken', resp.data.refreshToken)
          return resolve(true)
        } else {
          return reject(new Error('Error al renovar el token. Estado: ' + resp.status))
        }
      })
      .catch(err => {
        store.dispatch(accountActions.logout())
        reject(new Error('Error en la solicitud de refresh token: ' + err.message))
      })
  })

  //store.dispatch(accountActions.logout())
}

/********************************************************
 * To make the requests
 ********************************************************/

export function get(url: string, data: any, abortSignal?: AbortSignal) {
  let config = axiosConfig(data)

  if (abortSignal) config.signal = abortSignal

  return axios.get(apiUrl + url, config)
}

export function getFrontend(url: string) {
  return axios.get(url)
}

export async function authGet(url: string, data: any, abortSignal?: AbortSignal) {
  if (isAccessTokenExpired()) {
    await refreshToken()
  }

  return get(url, data, abortSignal)
}

export async function getBlob(url: string, data: any) {
  if (isAccessTokenExpired()) await refreshToken()

  const config = axiosConfig(data)
  config.responseType = 'blob' as ResponseType
  return axios.get(apiUrl + url, config)
}

export function post(url: string, data: any, headers: any = {}) {
  const contentType = 'application/json'
  const requestHeaders = { ...headers, contentType }
  const config = axiosConfig(data, requestHeaders)
  const body = { ...config.params }
  config.params = {}
  return axios.post(apiUrl + url, body, config)
}

export async function authPost(url: string, data: any, headers: any = {}) {
  if (isAccessTokenExpired()) await refreshToken()
  return post(url, data, headers)
}

export async function authPostBlob(url: string, data: any) {
  if (isAccessTokenExpired()) await refreshToken()
  const config = axiosConfig(data)
  const body = { ...config.params }
  config.params = {}
  config.responseType = 'blob' as ResponseType
  return axios.post(apiUrl + url, body, config)
}

export function postForm(url: string, data: any, headers: any = {}) {
  const config: AxiosRequestConfig | undefined = axiosConfig(data, headers)

  const options = {
    indices: true,
    nullsAsUndefineds: false,
    allowEmptyArrays: true,
  }
  const configToSend = {
    headers: {
      ...config?.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const body = serialize(config?.params, options)
  return axios.post(apiUrl + url, body, configToSend)
}

export async function authPostForm(url: string, data: any, headers: any = {}) {
  if (isAccessTokenExpired()) await refreshToken()
  return postForm(url, data, headers)
}

export function patch(url: string, data: any, headers: any = {}) {
  const contentType = 'application/json'
  const requestHeaders = { ...headers, contentType }
  const config = axiosConfig(data, requestHeaders)
  const body = { ...config.params }
  config.params = {}
  return axios.patch(apiUrl + url, body, config)
}

export async function authPatch(url: string, data: any, headers: any = {}) {
  if (isAccessTokenExpired()) await refreshToken()
  return patch(url, data, headers)
}
