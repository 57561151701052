import { useEffect, useState } from 'react'
import { useAppDispatch } from 'store/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { postForm, ENDPOINTS } from 'services/api'
import { actions as accountActions } from 'store/account'
import { LoginFormType } from 'containers/user/types'
import LoginForm from './components/loginForm/LoginForm'
import NeedsConfirmation from './components/needsConfirmation/NeedsConfirmation'
import Disclaimer from 'components/disclaimer/Disclaimer'

import styles from './Login.module.scss'

function Login(): JSX.Element {
  const dispatch = useAppDispatch()
  const loggedIn = useSelector((state: RootState) => state.account.loggedIn)
  const loginLoading = useSelector((state: RootState) => state.account.loading.login)
  const loginError = useSelector((state: RootState) => state.account.error)
  const email = useSelector((state: RootState) => state.account.email)
  const needsConfirmation = useSelector((state: RootState) => state.account.needsConfirmation)
  const [confirmationEmailSent, setConfirmationEmailSent] = useState<boolean>(false)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true)

  useEffect(() => {
    if (!loggedIn && localStorage.getItem('token')) {
      dispatch(accountActions.setLogin())
    }
  })

  const handleSubmit = (data: LoginFormType) => {
    dispatch(accountActions.postLogin(data))
  }

  const sendConfirmationEmail = () => {
    try {
      //TODO move this to store
      const data = { email: email }
      const resp = postForm(ENDPOINTS.sendConfirmationEmail, data)
      resp.then(r => setConfirmationEmailSent(true)).catch(error => console.log('An error occurred. Please try again.'))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={`${styles.base} ${styles.bigHeader}`}>
      {!needsConfirmation ? (
        <>
          <LoginForm
            submit={handleSubmit}
            loading={loginLoading}
            loginError={loginError}
            clearLoginError={() => dispatch(accountActions.setLoginError(''))}
          />

          {showDisclaimer && (
            <div className={styles.disclaimer}>
              <Disclaimer
                onClose={() => {
                  setShowDisclaimer(false)
                }}
              />
            </div>
          )}
        </>
      ) : (
        <NeedsConfirmation sendConfirmationEmail={sendConfirmationEmail} confirmationEmailSent={confirmationEmailSent} />
      )}
    </div>
  )
}

export default Login
