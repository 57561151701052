import { FaCircle, FaCircleHalfStroke, FaRegCircle } from 'react-icons/fa6'
import { Patent } from 'store/dashboard/types'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import styles from './InventionsTable.module.scss'
import { useState } from 'react'

type Props = {
  patents: Patent[]
}
interface Column {
  id: keyof Patent
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: any) => any
}

const InventionsTableMui = ({ patents }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const MissingData = () => <div className={styles.missingData}>Info not supplied by data provider</div>

  type DotsProps = {
    boostedScore: number
  }
  const Dots = ({ boostedScore }: DotsProps) => (
    <div
      className={styles.dots}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      title={`${boostedScore}`}>
      {showTooltip && <div className={styles.tooltip}>Normalized Rank: {boostedScore}</div>}
      <Circle boostedScore={boostedScore} completeCircle={5} halfCircle={0} />
      <Circle boostedScore={boostedScore} completeCircle={15} halfCircle={10} />
      <Circle boostedScore={boostedScore} completeCircle={30} halfCircle={20} />
      <Circle boostedScore={boostedScore} completeCircle={75} halfCircle={40} />
    </div>
  )
  type CircleProps = {
    boostedScore: number
    completeCircle: number
    halfCircle: number
  }
  const Circle = ({ boostedScore, completeCircle, halfCircle }: CircleProps) => (
    <>{boostedScore > completeCircle ? <FaCircle /> : boostedScore > halfCircle ? <FaCircleHalfStroke /> : <FaRegCircle />}</>
  )

  const capitalizeFirstLetter = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(w => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ')
  }

  const formatApplicants = (applicants: string[]): string => {
    return applicants.map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(' || ')
  }

  const columns: readonly Column[] = [
    { id: 'rank', label: 'Rank' },
    { id: 'boosted_score', label: 'Boosted score', format: value => <Dots boostedScore={value} /> },
    { id: 'applicants', label: 'Assignee', format: value => formatApplicants(value) },
    { id: 'title', label: 'Title' },
    { id: 'year', label: 'Priority Year' },
    { id: 'patent_num', label: 'Patent Number' },
    { id: 'num_of_patents_in_family', label: 'No. in family*' },
  ]

  return (
    <div className={styles.base}>
      {patents ? (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          {/* <TableContainer sx={{ maxHeight: 560 }}>
            <Table stickyHeader aria-label="sticky table" size="small"> */}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {patents.length === 0 && <td>No similar patents</td>}
                {patents.map(patent => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={patent.id}>
                      {columns.map(column => {
                        const value = patent[column.id]
                        if (column.id === 'patent_num') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {patent.patentUrl ? (
                                <a href={patent.patentUrl} target="_blank" rel="noreferrer">
                                  {patent.patent_num}
                                </a>
                              ) : (
                                <>{patent.patent_num}</>
                              )}
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value?.toString()}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <div>No data</div>
      )}
    </div>
  )
}

export default InventionsTableMui
