import { Applicant, ThirdPartyData } from 'store/dashboard/types'
import { Avatar, Box, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import JoinInnerIcon from '@mui/icons-material/JoinInner'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import styles from './ApplicantCard.module.scss'
import { ecolors } from 'containers/app/theme'
import { useState } from 'react'

type Props = {
  applicantList: Applicant[]
  thirdPartyData: ThirdPartyData | undefined
  target?: boolean
  multisectorSubgroups: string[]
}

export default function ApplicantCard({ multisectorSubgroups, applicantList, thirdPartyData, target }: Props) {
  const [selectedPosition, setSelectedPosition] = useState<number>(0)
  const applicant = applicantList[selectedPosition]
  const sectorNumbers = applicantList.map(applicant => multisectorSubgroups.indexOf(applicant.subgroup) + 1)

  const Title = () => {
    return (
      <Stack direction="row" className={styles.titleContainer} justifyContent="space-between">
        <Typography className={styles.title}>{applicant.applicant_original_name}</Typography>
        <Avatar sx={{ backgroundColor: ecolors.midgrey, height: '20px', width: '20px' }}>
          <AddIcon fontSize="small" />
        </Avatar>
      </Stack>
    )
  }

  const cardStyles = { backgroundColor: target ? 'primary.main' : 'transparent', borderRadius: '20px', borderColor: '#e8e8e8' }

  return (
    <div className={styles.base}>
      <Paper variant="outlined" className={`${styles.cardContainer} ${target && styles.target}`} sx={cardStyles}>
        <Box sx={{ p: 1.5 }}>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ maxWidth: 345 }}>
            <>
              <Stack className={styles.stack2}>
                <Title />
                <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between" sx={{ fontSize: '34px' }}>
                  <Box minWidth="40px">
                    {applicant.intersection_score_sum_normalized > 5 && (
                      <Tooltip title={`Normalization: ${applicant.intersection_score_sum_normalized}`}>
                        <JoinInnerIcon sx={{ color: target ? 'white' : 'green', fontSize: 'inherit' }} />
                      </Tooltip>
                    )}
                  </Box>
                  <Box minWidth="40px">
                    <AttachMoneyIcon sx={{ color: target ? 'white' : 'red', fontSize: 'inherit' }} />
                  </Box>
                  <Box minWidth="40px">
                    {thirdPartyData && thirdPartyData.ticker && (
                      <AccountBalanceIcon sx={{ color: target ? 'white' : 'grey', fontSize: 'inherit' }} />
                    )}
                  </Box>
                </Stack>
              </Stack>
            </>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: '14px', color: target ? 'white' : 'green' }}>
            Sectors ({sectorNumbers.length}):{' '}
            {sectorNumbers.map((sector, index) => (
              <span
                key={index}
                onClick={() => setSelectedPosition(index)} // Actualiza selectedPosition cuando se hace clic en un sector
                style={{ cursor: 'pointer' }}>
                {index === selectedPosition ? (
                  <Tooltip title={applicant.subgroup}>
                    <span>
                      <strong>{sector}</strong>{' '}
                    </span>
                  </Tooltip>
                ) : (
                  sector
                )}
                {index < sectorNumbers.length - 1 && ', '}
              </span>
            ))}
          </Stack>
        </Box>
      </Paper>
    </div>
  )
}
