import React, { ReactNode } from 'react'
import { Box, styled, TextareaAutosize } from '@mui/material'
import { blue, grey } from '@mui/material/colors'

const borderRadiusValue = '15px'

export const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 650px;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 15px 12px;
  border-radius:${borderRadiusValue};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${grey[400]};
  box-shadow: 0 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: black;
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  /* firefox */
  &:focus-visible {
    outline: 0;
  }
`
)
// Componente que envuelve a todos los TextFields con los estilos específicos
export const ReportRequestStyledWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        '& .MuiTextField-root': {
          display: 'flex',
          flexDirection: 'column',
          width: '650px',
          backgroundColor: 'white',
          borderRadius: borderRadiusValue,
        },
        '& .MuiInputBase-root': {
          color: 'primary.main',
          borderRadius: borderRadiusValue,
        },

        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.main',
        },
      }}>
      {children} {/* Los hijos (TextFields) son renderizados aquí */}
    </Box>
  )
}
