import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import { authPatch, ENDPOINTS } from 'services/api'
import Spinner from 'components/spinner/Spinner'
import { FiExternalLink as ExternalLink } from 'react-icons/fi'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Title from 'components/title/Title'
import styles from './UsersList.module.scss'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material'

const UsersList = () => {
  const dispatch = useAppDispatch()
  const users = useSelector((state: RootState) => state.adminArea.users)
  const isLoading = useSelector((state: RootState) => state.adminArea.loading.users)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    dispatch(dashboardActions.getUsers('insights'))
  }, [dispatch])

  const updateUserDate = (id: string, key: string, date: Date) => {
    //TODO: update startDate doesn't work if endDate is not set.
    const userData = users?.find(user => user.id === id)
    const updatedUser = {
      ...userData,
      [key]: date.getTime(),
    }

    const resp = authPatch(ENDPOINTS.updateUser(id), updatedUser)
    resp.then(r => {
      refreshUser(id, key, date)
    })
    resp.catch(error => {
      setError(error?.message)
    })
  }

  const refreshUser = (id: string, key: string, value: any) => {
    const newState = users?.map(user => {
      if (user.id === id) return { ...user, [key]: value }
      return user
    })
    dispatch(dashboardActions.setUsers(newState))
  }

  const getDate = (date: string) => {
    if (date) {
      return new Date(date)
    } else return null
  }

  return (
    <div className={styles.base}>
      <Box m={3}>
        <Title>Admin Area</Title>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" m={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Lists</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Registration date</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Searches last week</TableCell>
                <TableCell>Searches last month</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map(user => (
                  <TableRow hover>
                    <TableCell>
                      <span className={styles.name}>
                        {user.firstName} {user.lastName}
                      </span>
                      <br />
                      <span className={styles.email}>{user.email}</span>
                    </TableCell>
                    <TableCell style={{ width: 120 }}>
                      <Link to={`/settings/admin/user/${user.id}/searches`} className={styles.searchesLink}>
                        Searches <ExternalLink size={'0.8em'} />
                      </Link>
                      <Stack m={0.4} />
                      <Link to={`/settings/admin/user/${user.id}/multisectors`} className={styles.multisectorsLink}>
                        Multisectors <ExternalLink size={'0.8em'} />
                      </Link>
                    </TableCell>
                    <TableCell>{user.roles.split(',').join(', ')}</TableCell>

                    <TableCell>{user.createdAt?.slice(0, -2)}</TableCell>
                    <TableCell className={styles.date}>
                      <DatePicker
                        selected={getDate(user.startDate)}
                        onChange={(date: Date) => updateUserDate(user.id, 'startDate', date)}
                      />
                    </TableCell>
                    <TableCell className={styles.date}>
                      <DatePicker
                        selected={getDate(user.endDate)}
                        onChange={(date: Date) => updateUserDate(user.id, 'endDate', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <span className={`${styles[user.status]}`}>{user.status}</span>
                    </TableCell>
                    <TableCell>{user.searchesLastWeek}</TableCell>
                    <TableCell>{user.searchesLastMonth}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box sx={{ textAlign: 'center', width: 1 }}>
            {isLoading && <CircularProgress />}
            {error && <div className={styles.error}>{error}</div>}
          </Box>
        </TableContainer>
      </Box>
    </div>
  )
}

export default UsersList
