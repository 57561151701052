import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions, ExportRequest } from 'store/adminArea'

import styles from './ResultTable.module.scss'
import { Search, Result } from 'store/commonTypes'
import Spinner from 'components/spinner/Spinner'
import IosShareIcon from '@mui/icons-material/IosShare'
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled, Button } from '@mui/material'

type Props = {
  userId: string
  search: Search
  results: Result[]
}

interface Column {
  id: keyof Result
  label: string
  minWidth?: number
  align?: 'right'

  format?: (value: any) => any
}

interface TruncatedTextProps {
  maxWidth?: number | string
}
const TruncatedText = styled('div', {
  shouldForwardProp: prop => prop !== 'width',
})<TruncatedTextProps>(({ maxWidth }: TruncatedTextProps) => ({
  maxWidth,
  height: 40,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
}))

const ResultTable = ({ userId, search, results }: Props) => {
  const columns: readonly Column[] = [
    {
      id: 'rank',
      label: '#',
    },
    { id: 'score', label: 'Score', format: score => score.toString().slice(0, 6) },
    { id: 'scoreBigrams', label: 'Score bigrams' },
    {
      id: 'country',
      label: 'Country',
    },
    {
      id: 'patentNum',
      label: 'Patent number',
    },
    {
      id: 'classificationsIPCR',
      label: 'Classes',
      format: classes => <TruncatedText maxWidth={300}>{classes.join(', ')}</TruncatedText>,
    },
    {
      id: 'applicants',
      label: 'Applicants',
    },
    {
      id: 'title',
      label: 'Title',
    },
    {
      id: 'wordMatches',
      label: 'Matched terms',
      format: wordMatches => <TruncatedText maxWidth={300}>{wordMatches.join(', ')}</TruncatedText>,
    },
  ]
  const searchId = search.ID
  const error = useSelector((state: RootState) => state.adminArea.error.results)
  const resultsLoading = useSelector((state: RootState) => state.adminArea.loading.results)
  const exportLoading = useSelector((state: RootState) => state.adminArea.loading.export)

  const exportForAnalysisLoading = useSelector((state: RootState) => state.adminArea.loading.exportForAnalysis)[searchId]
  const dispatch = useAppDispatch()
  const exportResults = () => {
    if (!exportLoading) {
      const data: ExportRequest = { userId, searchId, searchName: search.name }
      dispatch(dashboardActions.exportResults(data))
    }
  }

  const exportResultsForAnalysis = () => {
    if (!exportForAnalysisLoading) {
      const data: ExportRequest = { userId, searchId, searchName: search.name }
      dispatch(dashboardActions.exportResultsForAnalysis(data))
    }
  }

  return (
    <div className={styles.base}>
      <div className={styles.exportResultsContainer}>
        <Button
          size="small"
          onClick={exportResults}
          disabled={exportLoading}
          sx={{
            cursor: exportLoading ? 'wait' : 'pointer',
            mr: 3,
          }}>
          <IosShareIcon fontSize="inherit" sx={{ mr: 1 }} /> Export top 250 results
        </Button>

        <Button
          size="small"
          onClick={exportResultsForAnalysis}
          disabled={exportForAnalysisLoading}
          sx={{
            cursor: exportForAnalysisLoading ? 'wait' : 'pointer',
          }}>
          <IosShareIcon fontSize="inherit" sx={{ mr: 1 }} /> Export for analysis
        </Button>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {resultsLoading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {results?.length === 0 && <div>No results</div>}
      <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#eee' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.slice(0, 50).map(result => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={result.rank}>
                    {columns.map(column => {
                      const value = result[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value?.toString()}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default ResultTable
