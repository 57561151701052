const getFileType = (format: string) => {
  if (format === 'excel') return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  else if (format === 'csv') return 'text/csv;charset=utf-8;'
  else if (format === 'zip') return 'application/zip'
}

export function blobToFile(blob: any, filename: string, format: string) {
  const type = getFileType(format)
  const file = new Blob([blob], {
    type: type,
  })
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = filename
  link.click()
}
