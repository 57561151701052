import CompaniesTableOld from './companiesTableOld/CompaniesTableOld'
import InventionsTableOld from './inventionsTableOld/InventionsTableOld'
import InventionsTableMui from './inventionsTableMui/InventionsTableMui'
import styles from './Report.module.scss'

import { ReportData } from 'store/dashboard/types'
import CompaniesTable from './companiesTable/CompaniesTable'

type Props = {
  isPdfPrinter?: boolean
  reportData: ReportData
}

export default function Report({ isPdfPrinter = false, reportData }: Props) {
  return (
    <div id="report" className={`${styles.base} ${isPdfPrinter ? styles.pdf : ''}`}>
      <div className={styles.report}>
        <>
          <div className={styles.section}>
            Cutoff Year: {reportData.search_info.year_cutoff}
            <h2>Similar Companies</h2>
            <div className={styles.subTitle}>a) All Territories (excluding CN)</div>
            <CompaniesTable applicants={reportData.top_applicants_except_cn} />
            {/* TODO Slice commented, how to handle this, No podemos cortar aqui los 10 primeros y que luego en china se muestren resultados que esten en la posicion 30 por ejemplo <CompaniesTable applicants={reportData.top_applicants_except_cn.slice(0, 10)} /> */}
            <div className={styles.subTitle}>b) China (excluding all other territories)</div>
            <CompaniesTable applicants={reportData.top_applicants_for_cn} />
          </div>
          {reportData.primary_patents && reportData.primary_patents.length > 0 && (
            <div className={styles.section}>
              <h2>Primary Inventions</h2>
              <InventionsTableMui patents={reportData.primary_patents} />
              <InventionsTableOld patents={reportData.primary_patents} />
            </div>
          )}
          <div className={styles.pageBreak}></div>
          <div className={styles.section}>
            <h2>Similar Inventions</h2>
            <InventionsTableMui patents={reportData.top_non_primary_patents_except_cn} />
            <div className={styles.subTitle}>a) All Territories (excluding CN)</div>
            <InventionsTableOld patents={reportData.top_non_primary_patents_except_cn} />
            <div className={styles.subTitle}>b) China (excluding all other territories)</div>
            <InventionsTableMui patents={reportData.top_non_primary_patents_for_cn} />
            <InventionsTableOld patents={reportData.top_non_primary_patents_for_cn} />
          </div>
          <div>
            <p>• Primary Inventions are almost always populated by the IP portfolio if the Company being investigated.</p>
            <p>
              • Both Primary Inventions and Similar Inventions contain the top patents ranked by relevance to input text used to
              initiate the search.
            </p>
            <p>• In the Beta version the patent claims only are analysed to identify relevance.</p>
            <p>• The Beta version uses patent data available up to February 2023.</p>
            <p>• If priority year is missing, publication year is shown instead (marked with *)</p>
            <p>• The patent number will link to the patent identified.</p>
            <p>• 'No. in family' means 'Number of patents in Patent Family'</p>
            <p>• '**'' means 'Grouped because title and applicant are the same'</p>
          </div>
        </>
      </div>
    </div>
  )
}
