import React, { ReactNode } from 'react'
import { Paper, PaperProps } from '@mui/material'

interface CustomPaperProps extends PaperProps {
  children: ReactNode
}

const SectionContainer: React.FC<CustomPaperProps> = ({ children, ...props }) => {
  return (
    <Paper variant="outlined" sx={{ my: 2.5, bgcolor: '#f6f8fa', borderRadius: '20px', borderColor: '#8a8a8a' }} {...props}>
      {children}
    </Paper>
  )
}

export default SectionContainer
