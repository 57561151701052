import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import ReCaptcha from 'components/reCaptcha/ReCaptcha'
import Button from 'components/button/Button'
import FormInput from 'components/formInput/FormInput'
import { LoginFormType } from 'containers/user/types'
import CardTitle from 'components/cardTitle/CardTitle'
import styles from './LoginForm.module.scss'

type Props = {
  submit: (event: any) => void
  loading: boolean
  loginError: null | string
  clearLoginError: () => void
}

function LoginForm({ submit, loading, loginError, clearLoginError }: Props): JSX.Element {
  const [loginSubmitted, setLoginSubmitted] = useState<boolean>(false)
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false)
  const [data, setData] = useState<LoginFormType>({
    email: '',
    password: '',
  })

  const handleInputChange = (event: any) => {
    setLoginSubmitted(false)
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    clearLoginError()
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    clearLoginError()
    setLoginSubmitted(true)
    submit(data)
  }

  return (
    <div className={styles.card}>
      <CardTitle data-testid="loginForm">Sign in to your account.</CardTitle>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormInput onChange={handleInputChange} name="email" label="Email" value={data.email} />
          <FormInput onChange={handleInputChange} name="password" label="Password" value={data.password} type="password" />
          {loginError && (
            <div className={styles.loginError} data-testid="loginForm-error-text">
              {loginError}
            </div>
          )}
          {loginError && (
            <div className={styles.recaptcha}>
              <ReCaptcha onChange={() => setCaptchaVerified(true)} />
            </div>
          )}

          <div className={styles.btnContainer}>
            <Button
              title="Sign in"
              type="submit"
              data-testid="button"
              disabled={
                !data.email || !data.password || (loginSubmitted && !loginError) || (Boolean(loginError) && !captchaVerified)
              }
              loading={loading}
              big
            />
          </div>
          <div className={styles.forgotPassword}>
            <Link to="/account-recovery">Recover password</Link>
          </div>

          <div className={styles.createAccountContainer}>
            <div className={styles.createAccount}>
              You don't have an account?
              <Link to="/signup">Sign up</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
