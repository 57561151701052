import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'store/hooks'
import { actions as searchActions } from 'store/search'

import { get, ENDPOINTS } from 'services/api'
import CategoryPicker from './components/categoryPicker/CategoryPicker'
import { Category } from 'containers/search/types'
import { Box, Button, Link, TextField, Typography } from '@mui/material'
import { SearchRequest } from 'store/search/types'
import { Textarea, ReportRequestStyledWrapper } from './ReportRequestStyle'
import CountryPicker from './components/countryPicker/CountryPicker'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNavigate } from 'react-router'
import Title from 'components/title/Title'

function ReportRequestPage() {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [searchName, setSearchName] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [patentNumber, setPatentNumber] = useState<string>('')
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([])
  const [countriesFilter, setCountriesFilter] = useState<any[]>([])
  const [searchIsSubmitted, setSearchIsSubmitted] = useState<boolean>(false)

  const [categoriesLevel1, setCategoriesLevel1] = useState<Category[]>([])
  const [categoriesLevel2, setCategoriesLevel2] = useState<Category[]>([])
  const [searchNameError, setSearchNameError] = useState<null | string>(null)
  const [searchTextError, setSearchTextError] = useState<boolean>(false)
  const [patentNumberError, setPatentNumberError] = useState<boolean>(false)
  const [ipcFilterError, setIpcFilterError] = useState<boolean>(false)
  const [countryFilterError, setCountryFilterError] = useState<boolean>(false)
  const searches = useSelector((state: RootState) => state.search.searches)
  const searchError = useSelector((state: RootState) => state.search.searchError)

  useEffect(() => {
    dispatch(searchActions.clearStatus())
    fetchCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isValidSearchNameInput = () => {
    if (!searchName) {
      setSearchNameError('Required')
      return false
    }
    const regex = /[<>:"/\\|?*\x00]/
    if (regex.test(searchName)) {
      const matches = searchName.match(regex)
      setSearchNameError('Name contains special characters: ' + matches)
      return false
    } else return true
  }
  const formIsValid = () => {
    let isValid = true
    isValid = isValidSearchNameInput()

    if (!patentNumber && !text) {
      setPatentNumberError(true)
    }
    if (text) {
      var words = text.trim().split(/\s+/).length
      if (!text || words < 2) {
        isValid = false
        setSearchTextError(true)
      }
    }

    if (categoriesFilter.length === 0) {
      isValid = false
      setIpcFilterError(true)
    }

    if (countriesFilter.length === 0) {
      isValid = false
      setCountryFilterError(true)
    }

    return isValid
  }

  const handleSubmit = () => {
    if (formIsValid()) {
      setSearchIsSubmitted(true)
      const dispatchData: SearchRequest = {
        name: searchName,
        ...(patentNumber ? { patent_number: patentNumber } : {}),
        ...(text ? { text } : {}),
        filter: { classificationsIPCR: categoriesFilter, countries: countriesFilter },
      }

      dispatch(searchActions.submitSearch(dispatchData))
    }
  }

  const fetchCategories = () => {
    // Checks to see if categories are already fetched and if not then gets them
    if (categoriesLevel1.length === 0 || categoriesLevel2.length === 0) {
      const resp1 = get(ENDPOINTS.categories, { level: 1 })
      resp1.then(r => setCategoriesLevel1(r.data)).catch(error => console.log(error))

      const resp2 = get(ENDPOINTS.categories, { level: 2 })
      resp2.then(r => setCategoriesLevel2(r.data)).catch(error => console.log(error))
    }
  }

  const updateCategoriesFilter = useCallback(
    (value: (string | number)[]) => {
      setCategoriesFilter(value)
    },
    [setCategoriesFilter]
  )
  const updateCountryFilter = useCallback((countries: (string | number)[]) => {
    setCountriesFilter(countries)
  }, [])

  const flatternList = (list: (string | number)[]) => {
    let flattened = list
      .map(l => {
        if (typeof l === 'string') {
          return l.split(',')
        } else return l
      })
      .flat()

    return flattened
  }

  useEffect(() => {
    if (searchIsSubmitted && searches.length > 0) {
      history(`/report/${searches[0].ID}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches])

  return (
    <ReportRequestStyledWrapper>
      <Box p={4}>
        <Title pb={4}>Create New report</Title>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              required
              error={Boolean(searchNameError)}
              id="search-name"
              name="searchName"
              label="Give your report a title"
              value={searchName}
              sx={{ mb: 3 }}
              onChange={event => {
                setSearchNameError(null)
                setSearchName(event?.target.value)
              }}
            />
            <TextField
              required
              error={Boolean(patentNumberError)}
              id="search-name"
              name="searchName"
              label="Add a patent number"
              value={patentNumber}
              onChange={event => {
                setPatentNumberError(false)
                setText('')
                setPatentNumber(event?.target.value)
              }}
            />

            <Box display={showAdvancedOptions ? '' : 'none'}>
              <Typography ml={3} sx={{ fontStyle: 'italic' }}>
                or
              </Typography>
              <Textarea
                maxRows={6}
                aria-label="maximum height"
                placeholder="Paste patent claims text here"
                onChange={event => {
                  setPatentNumber('')
                  setPatentNumberError(false)
                  setText(event?.target.value)
                }}
                defaultValue={text}
              />
              {searchTextError && (
                <Typography ml={2} sx={{ fontSize: 13, color: 'red' }}>
                  2 words or more.
                </Typography>
              )}

              <></>
              <Box my={3}>
                <CategoryPicker
                  unfilteredCategoriesLevel1={categoriesLevel1}
                  unfilteredCategoriesLevel2={categoriesLevel2}
                  onSelectChange={(val: (string | number)[]) => {
                    setIpcFilterError(false)
                    updateCategoriesFilter(flatternList(val))
                  }}
                  onOpen={fetchCategories}
                  error={ipcFilterError}
                />
              </Box>
              <CountryPicker
                onSelectChange={(val: (string | number)[]) => {
                  setCountryFilterError(false)
                  updateCountryFilter(val)
                }}
                error={countryFilterError}
              />
            </Box>

            <Link
              href="#"
              onClick={() => {
                setShowAdvancedOptions(prevState => !prevState)
              }}
              sx={{ mt: 2, ml: 2, cursor: 'pointer', display: 'block', fontSize: '14px' }}>
              {showAdvancedOptions ? <>Close search options</> : <>See search options (advanced)</>}
            </Link>
            <Box mt={4}>
              <Button variant="contained" sx={{ px: 6, py: 2 }} onClick={() => handleSubmit()}>
                Create new report
              </Button>
            </Box>
            {searchError && (
              <Typography width={620} sx={{ fontSize: 13, color: 'red', wordBreak: 'break-all' }}>
                {searchError}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ReportRequestStyledWrapper>
  )
}

export default ReportRequestPage
