import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TopApplicant } from 'store/dashboard/types'
import SchoolIcon from '@mui/icons-material/School'
import { Box, Tooltip } from '@mui/material'

type Props = {
  applicants: TopApplicant[]
}

interface Column {
  id: keyof TopApplicant
  label: string
  minWidth?: number
  align?: 'right'
  padding?: number
  format?: (value: any) => any
}

export default function CompaniesTable({ applicants }: Props) {
  const columns: readonly Column[] = [
    {
      id: 'research',
      label: 'Research',
      padding: 0.2,
      format: (isResearch: string) => {
        if (isResearch) return <SchoolIcon sx={{ fontSize: 19 }} />
      },
    },
    { id: 'rank', label: 'Rank' },
    { id: 'applicant_name', label: 'Assignee' },
    {
      id: 'origins',
      label: 'Origin',
      format: (origins: string[]) => origins.join(', ').toUpperCase(),
    },
    { id: 'protected_territories', label: 'Protected Territories' },
    {
      id: 'non_protected_territories',
      label: 'Seeking Protection',

      format: (value: string) => value.toString().split(',').join(', '),
    },
  ]

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {applicants.map(applicant => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={applicant.rank}>
                    {columns.map(column => {
                      const value = applicant[column.id]
                      if (column.id === 'applicant_name') {
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ py: column.padding }}>
                            {value}

                            {applicant.applicant_list_names.length > 0 && (
                              <Tooltip placement="bottom-start" arrow title="Alternative name">
                                <Box color={'grey'}>
                                  {applicant.applicant_list_names.map((altName, index) => (
                                    <div key={index}>[{altName}]</div>
                                  ))}
                                </Box>
                              </Tooltip>
                            )}

                            {applicant.related_companies.map((related_company, index, array) => (
                              <Tooltip placement="bottom-start" arrow title="Related companies">
                                <Box color={'grey'}>
                                  {applicant.applicant_list_names.map((altName, index) => (
                                    <div key={index}>
                                      {related_company}
                                      {index < array.length - 1 && ', '}
                                    </div>
                                  ))}
                                </Box>
                              </Tooltip>
                            ))}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align} sx={{ py: column.padding }}>
                            {column.format ? column.format(value) : value?.toString()}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
