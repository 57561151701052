import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchesList from 'containers/tables/searchesList/SearchesList'
import Title from 'components/title/Title'

export default function SearchesListPage() {
  const userId = useSelector((state: RootState) => state.account.userId)
  return (
    <Box alignItems="center" gap={2} m={4}>
      <Title sx={{ p: 1, pb: 4 }}>My single reports</Title>
      <SearchesList userId={userId} />
    </Box>
  )
}
