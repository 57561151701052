import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/dashboard'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Link } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

type Props = {
  userId: string
}
export default function MultisectorsList({ userId }: Props) {
  const dispatch = useAppDispatch()
  const data = useSelector((state: RootState) => state.dashboard.multisectorsList)
  const isLoading = useSelector((state: RootState) => state.dashboard.loading.multisectorsList)
  const error = useSelector((state: RootState) => state.dashboard.error.multisectorsList)
  useEffect(() => {
    dispatch(dashboardActions.fetchMultisectors(userId))
  }, [dispatch, userId])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>

            <TableCell>Start date</TableCell>
            <TableCell>End date</TableCell>
            <TableCell>Limit</TableCell>
            <TableCell style={{ maxWidth: 100 }}>Searches selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map(row => (
              <TableRow hover>
                <TableCell>
                  <Link to={'/dashboard/reporting/multisector/' + row.id} target="_blank">
                    {row.id} <OpenInNewIcon sx={{ fontSize: 13 }} />
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.created_at.replace('.0', '')}</TableCell>
                <TableCell>{row.updated_at.replace('.0', '')}</TableCell>

                <TableCell>{row.request_body.startDate}</TableCell>
                <TableCell>{row.request_body.endDate}</TableCell>
                <TableCell>{row.request_body.limit}</TableCell>
                <TableCell>{row.request_body.searchIds.length}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {data && data?.length === 0 && <Stack p={2}>No multisectors created</Stack>}
      <Box sx={{ textAlign: 'center', width: 1 }}>
        {isLoading && <CircularProgress />}
        {error && (
          <Typography p={1} color={'red'}>
            {error}
          </Typography>
        )}
      </Box>
    </TableContainer>
  )
}
