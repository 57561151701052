import { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { ThemeProvider } from '@mui/material/styles'
import { useAppDispatch } from 'store/hooks'
import { actions as accountActions } from 'store/account'
import { actions as pingerActions } from 'store/pinger'
import useInterval from 'use-interval'
import { isTokenExpired, refreshToken } from 'services/api'

import Header from 'components/header/Header'
import NotificationBanner from 'components/notificationBanner/NotificationBanner'
import SidePanel from 'components/sidePanel/SidePanel'
import BetaProgramme from 'containers/user/containers/betaProgramme/BetaProgramme'
import Login from 'containers/user/containers/login/Login'
import SignUp from 'containers/user/containers/signUp/SignUp'
import Confirmation from 'containers/user/containers/confirmation/Confirmation'
import AccountRecovery from 'containers/user/containers/accountRecovery/AccountRecovery'
import ResetPassword from 'containers/user/containers/resetPassword/ResetPassword'
import UpdatePassword from 'containers/user/containers/updatePassword/UpdatePassword'
import UsersList from 'containers/adminArea/containers/usersList/UsersList'
import ReportRequest from 'containers/search/textSearch/reportRequestPage/ReportRequestPage'
import SearchWaitPage from 'containers/search/textSearch/searchWaitPage/SearchWaitPage'
import CompanySearch from 'containers/search/companySearch/companySearchPage/CompanySearchPage'
import CompanySearchWaitPage from 'containers/search/companySearch/companySearchWaitPage/CompanySearchWaitPage'
import PdfPrintReport from 'containers/dashboard/singleReportPage/pdfPrintReport/PdfPrintReport'
import SingleReportPage from 'containers/dashboard/singleReportPage/SingleReportPage'
import { ReactComponent as Logo } from 'assets/images/insights-IDS.svg'
import styles from './App.module.scss'
import Multisector from 'containers/dashboard/multisector/Multisector'
import theme from './theme'
import Dashboard from 'containers/dashboard/Dashboard'

import SearchesListPage from 'containers/dashboard/searchesListPage/SearchesListPage'
import UserSearchesPage from 'containers/adminArea/containers/userSearchesPage/UserSearchesPage'
import MultisectorsListPage from 'containers/dashboard/multisectorsListPage/MultisectorsListPage'
import UserMultisectorsPage from 'containers/adminArea/containers/userMultisectorsPage/UserMultisectorsPage'
import Sidebar from 'components/sideBar/SideBar'
import { Box, CssBaseline } from '@mui/material'

const drawerWidth = 240

function App() {
  const dispatch = useAppDispatch()
  const loggedIn = useSelector((state: RootState) => state.account.loggedIn)
  const releaseDate = useSelector((state: RootState) => state.pinger.releaseDate)
  const [hasUpdate, setHasUpdate] = useState(false)
  const releaseDateRef = useRef<string>()

  // on refresh or app load check whether logged in
  useEffect(() => {
    refreshToken()
  }, [])

  useInterval(
    () => {
      if (isTokenExpired(localStorage.getItem('refreshToken'))) {
        dispatch(accountActions.logout())
      }
    },
    loggedIn ? 2000 : null
  ) // passing null will cancel the interval if it is already running

  useEffect(() => {
    if (releaseDate && !releaseDateRef.current) {
      releaseDateRef.current = releaseDate
    }
  })

  useEffect(() => {
    dispatch(pingerActions.getMaintenanceStatus())

    const interval = setInterval(() => {
      dispatch(pingerActions.getMaintenanceStatus())
    }, 100000)
    return () => clearInterval(interval)
  }, [dispatch])

  useEffect(() => {
    if (!hasUpdate && releaseDate && releaseDateRef.current !== releaseDate) {
      setHasUpdate(true)
    }
  }, [releaseDate, hasUpdate])

  const LogoComponent = () => {
    return (
      <Box display="flex" justifyContent="center">
        <Link to="/">
          <Logo height={80} />
        </Link>
      </Box>
    )
  }
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/pdfprinter/report/:id" element={<PdfPrintReport />} />
          </Routes>

          {!window.location.pathname.startsWith('/pdfprinter') && (
            <div className={styles.base}>
              {hasUpdate && <NotificationBanner />}
              {!loggedIn && (
                <>
                  <LogoComponent />
                  <Routes>
                    <Route path="/index.html" element={<Navigate replace to="/" />} />
                    <Route path="/private-beta-registration" element={loggedIn ? <Navigate replace to="/" /> : <SignUp />} />
                    <Route path="/signup" element={loggedIn ? <Navigate replace to="/" /> : <BetaProgramme />} />
                    <Route path="/login" element={loggedIn ? <Navigate replace to="/" /> : <Login />} />
                    <Route path="/confirmation/:token" element={loggedIn ? <Navigate replace to="/" /> : <Confirmation />} />
                    <Route path="/account-recovery" element={loggedIn ? <Navigate replace to="/" /> : <AccountRecovery />} />
                    <Route path="/reset/:token" element={loggedIn ? <Navigate replace to="/" /> : <ResetPassword />} />
                    {!loggedIn && <Route path="/*" element={<Login />} />}
                  </Routes>
                </>
              )}
              {loggedIn && (
                <Box sx={{ display: 'flex' }}>
                  <CssBaseline />

                  <Sidebar />

                  {/* <SidePanel /> */}
                  <Box component="main" sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <Header />
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/settings/security" element={<UpdatePassword />} />
                      <Route path="/settings/admin" element={<UsersList />} />
                      <Route path="/settings/admin/user/:id/searches" element={<UserSearchesPage />} />
                      <Route path="/settings/admin/user/:id/multisectors" element={<UserMultisectorsPage />} />
                      <Route path="/company/search" element={<CompanySearch />} />
                      <Route path="/dashboard/reporting" element={<Dashboard />} />
                      <Route path="/dashboard/reporting/report" element={<SearchesListPage />} />
                      <Route path="/dashboard/reporting/report/:id" element={<SingleReportPage />} />
                      <Route path="/dashboard/reporting/multisector" element={<MultisectorsListPage />} />
                      <Route path="/dashboard/reporting/multisector/:id" element={<Multisector />} />
                      <Route path="/company/search/:id" element={<CompanySearchWaitPage />} />
                      <Route path="/report" element={<ReportRequest />} />
                      <Route path="/report/confirmation" element={<ReportRequest />} />
                      <Route path="/report/:id" element={<SearchWaitPage />} />
                    </Routes>
                  </Box>
                </Box>
              )}
            </div>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  )
}

export default App
