import { put, takeLatest, call } from 'redux-saga/effects'
import axios, { AxiosResponse } from 'axios'

import * as Api from 'services/api'
import { ENDPOINTS, authGet, authPost, authPostBlob } from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MultisectorData, ThirdPartyData, ReportData, MultisectorDbRow } from './types'
import { blobToFile } from 'store/utils'

//Each slice file should define a type for its initial state value, so that createSlice can correctly infer the type of
//state in each case reducer.
//All generated actions should be defined using the PayloadAction<T> type from Redux Toolkit, which takes the type
//of the action.payload field as its generic argument.

/**
 * Shape of the stores state
 */

export type ReportDataRequest = {
  id: string
  limit?: number
  isPdfPrinter?: boolean
  regenerate?: boolean
  yearCutoff?: number
}

type ErrorKeyValueType = {
  key: keyof errorTypes
  value: null | string
}

type LoadingKeyValueType = {
  key: keyof loadingTypes
  value: boolean
}

type loadingTypes = {
  reportData: boolean
  downloadPdf: boolean
  multisectorData: boolean
  multisectorZip: boolean
  multisectorsList: boolean
  regenerateMultisector: boolean
}

type errorTypes = {
  searchStatus: string | null
  reportData: string | null
  downloadPdf: string | null
  multisectorStatus: string | null
  multisectorData: string | null
  multisectorZip: string | null
  regenerateMultisector: string | null
  multisectorsList: string | null
}

export type MultisectorStatus =
  | 'multisector_started'
  | 'multisector_processing'
  | 'multisector_error'
  | 'whales_error'
  | 'whales_finished'
  | 'comparison_processing'
  | 'comparison_finished'
  | 'comparison_error'
  | 'regenerating'

export interface DashboardState {
  reportData: ReportData | null
  multisectorStatus: MultisectorStatus | null
  multisectorData: MultisectorData | null
  multisectorThirdPartyDataList: ThirdPartyData[] | null
  multisectorsList: MultisectorDbRow[] | null
  loading: loadingTypes
  error: errorTypes
}

/**
 * Initial value(s) of the stores state
 */
const initialState: DashboardState = {
  reportData: null,
  multisectorStatus: null,
  multisectorData: null,
  multisectorThirdPartyDataList: null,
  multisectorsList: null,
  loading: {
    reportData: false,
    downloadPdf: false,
    multisectorData: false,
    multisectorZip: false,
    multisectorsList: false,
    regenerateMultisector: false,
  },
  error: {
    searchStatus: null,
    reportData: null,
    downloadPdf: null,
    multisectorStatus: null,
    multisectorData: null,
    multisectorZip: null,
    regenerateMultisector: null,
    multisectorsList: null,
  },
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchReportData: (state, action: PayloadAction<ReportDataRequest>) => {},
    setReportData: (state, action: PayloadAction<ReportData | null>) => {
      state.reportData = action.payload
    },
    downloadPdf: (
      state,
      action: PayloadAction<{
        searchId: string
        pdfFileName: string
      }>
    ) => {},
    fetchMultisectorData: (state, action: PayloadAction<string>) => {},
    setMultisectorData: (state, action: PayloadAction<MultisectorData | null>) => {
      state.multisectorData = action.payload
    },
    getMultisectorStatus: (state, action: PayloadAction<string>) => {},
    setMultisectorStatus: (state, action: PayloadAction<MultisectorStatus>) => {
      state.multisectorStatus = action.payload
    },
    exportMultisectorZip: (state, action: PayloadAction<string>) => {},
    fetchMultisectorThirdPartyData: (state, action: PayloadAction<string>) => {},
    setMultisectorThirdPartyData: (state, action: PayloadAction<ThirdPartyData[] | null>) => {
      state.multisectorThirdPartyDataList = action.payload
    },
    regenerateMultisector: (state, action: PayloadAction<string>) => {},
    fetchMultisectors: (state, action: PayloadAction<string>) => {},
    setMultisectorsList: (state, action: PayloadAction<MultisectorDbRow[]>) => {
      state.multisectorsList = action.payload
    },
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
    setError: (state, action: PayloadAction<ErrorKeyValueType>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeLatest(actions.fetchReportData, fetchReportDataSaga)
  yield takeLatest(actions.downloadPdf, downloadPdfSaga)
  yield takeLatest(actions.fetchMultisectorData, fetchMultisectorDataSaga)
  yield takeLatest(actions.exportMultisectorZip, exportMultisectorZipSaga)
  yield takeLatest(actions.getMultisectorStatus, getMultisectorStatusSaga)
  yield takeLatest(actions.fetchMultisectorThirdPartyData, fetchMultisectorThirdPartyDataSaga)
  yield takeLatest(actions.regenerateMultisector, regenerateMultisectorSaga)
  yield takeLatest(actions.fetchMultisectors, fetchMultisectorsSaga)
}

/**
 * Sagas
 */

const setLoadingTrue = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: true }))
}

const setLoadingFalse = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: false }))
}

const fetchReportDataSaga = function* (action: PayloadAction<ReportDataRequest>) {
  try {
    yield setLoadingTrue('reportData')
    yield put(actions.setReportData(null))
    yield put(actions.setError({ key: 'reportData', value: null }))
    const searchId = action.payload.id
    const isPdfPrinter = Boolean(action.payload.isPdfPrinter)
    const body = {
      ...(action.payload.yearCutoff && { yearCutoff: action.payload.yearCutoff }),
      ...(action.payload.limit && { limit: action.payload.limit }),
      ...(action.payload.regenerate && { regenerate: action.payload.regenerate }),
    }

    const response: AxiosResponse<ReportData> = yield call(authPost, ENDPOINTS.reportData(searchId, isPdfPrinter), body)
    if (response.status === 200) {
      const resp = response.data
      yield put(actions.setReportData(resp))
    }
    yield setLoadingFalse('reportData')
    console.log(response.data)
  } catch (error: any) {
    console.log(error?.response?.message)
    yield setLoadingFalse('reportData')
    yield put(actions.setError({ key: 'reportData', value: error?.message }))
  }
}

const fetchMultisectorThirdPartyDataSaga = function* (action: PayloadAction<string>) {
  try {
    // yield setLoadingTrue TODO
    const id = action.payload
    const response: AxiosResponse<ThirdPartyData[]> = yield call(authPost, ENDPOINTS.multisectorThirdPartyData(id), {})
    yield put(actions.setMultisectorThirdPartyData(response.data))
    const body = {
      multisector_id: id,
      owler_data: response.data,
    }
    const url = String(process.env.REACT_APP_TEMP_OWLER_MERGER_LAMBDA)
    const resp: AxiosResponse<any> = yield axios.post(url, body)
    console.log(resp)
  } catch (error: any) {
    console.log(error)
  }
}

// Validates that has all fields from interface Applicant
const hasRequiredFields = (multisectorJson: any): boolean => {
  //Validating the first element is enough
  const data = multisectorJson[0]
  return (
    data.applicant_cleaned !== undefined &&
    data.applicant_original_name !== undefined &&
    data.subgroup !== undefined &&
    data.intersection_score_sum !== undefined &&
    data.count_in_subgroup !== undefined &&
    data.intersection_score_sum_normalized !== undefined &&
    data.count_in_subgroup_normalized !== undefined &&
    data.research !== undefined &&
    data.whales_categories_count !== undefined &&
    data.whales_categories_count_normalized !== undefined &&
    data.whales_patent_count !== undefined &&
    data.whales_patent_in_percent !== undefined &&
    data.whales_patent_out_categories !== undefined &&
    data.whales_patents_in_categories !== undefined &&
    data.comparison_score !== undefined &&
    data.comparison_score_normalized !== undefined
  )
}

const fetchMultisectorDataSaga = function* (action: PayloadAction<string>) {
  try {
    yield setLoadingTrue('multisectorData')
    yield put(actions.setReportData(null))
    yield put(actions.setError({ key: 'multisectorData', value: null }))
    const id = action.payload

    const response: AxiosResponse<MultisectorData> = yield call(authPost, ENDPOINTS.multisectorData(id), {})
    if (response.status === 200) {
      const { multisector } = response.data

      if (hasRequiredFields(multisector)) {
        yield put(actions.setMultisectorData(response.data))
      } else {
        yield put(actions.setError({ key: 'multisectorData', value: 'Invalid multisector json. Please, regenerate it.' }))
      }
    } else {
      yield put(actions.setError({ key: 'multisectorData', value: 'status not 200' }))
    }
    yield setLoadingFalse('multisectorData')
    console.log(response.data)
  } catch (error: any) {
    const errorMsg = error?.response?.message || error?.response?.data
    console.log(errorMsg)
    yield setLoadingFalse('multisectorData')
    yield put(actions.setError({ key: 'multisectorData', value: errorMsg }))
  }
}

const exportMultisectorZipSaga = function* (action: PayloadAction<string>) {
  try {
    yield setLoadingTrue('multisectorZip')
    yield put(actions.setReportData(null))
    yield put(actions.setError({ key: 'multisectorData', value: null }))
    const id = action.payload

    const response: AxiosResponse<MultisectorData> = yield call(authPostBlob, ENDPOINTS.multisectorZip(id), {})
    blobToFile(response.data, 'multisector', 'zip')
    yield setLoadingFalse('multisectorZip')
    console.log(response.data)
  } catch (error: any) {
    const errorMsg = error?.response?.message || error?.response?.data
    console.log(errorMsg)
    yield setLoadingFalse('multisectorZip')
    yield put(actions.setError({ key: 'multisectorZip', value: errorMsg })) //TODO
  }
}

const getMultisectorStatusSaga = function* (action: PayloadAction<string>) {
  try {
    yield put(actions.setError({ key: 'multisectorStatus', value: null }))
    const id = action.payload

    const response: AxiosResponse<MultisectorStatus> = yield call(authPost, ENDPOINTS.multisectorStatus(id), {})
    if (response.status === 200) {
      if (response.data !== 'comparison_finished') {
        yield put(actions.setError({ key: 'multisectorData', value: null }))
      }
      yield put(actions.setMultisectorStatus(response.data))
    }
    console.log(response.data)
  } catch (error: any) {
    const errorMsg = error?.response?.message || error?.response?.statusText
    console.log(errorMsg)
    yield put(actions.setError({ key: 'multisectorStatus', value: errorMsg }))
  }
}

const regenerateMultisectorSaga = function* (action: PayloadAction<string>) {
  try {
    yield put(actions.setError({ key: 'multisectorData', value: null }))
    yield put(actions.setError({ key: 'regenerateMultisector', value: null }))
    yield setLoadingTrue('regenerateMultisector')
    const id = action.payload
    yield put(actions.setMultisectorStatus('regenerating'))

    const response: AxiosResponse<MultisectorStatus> = yield call(authPost, ENDPOINTS.regenerateMultisector(id), {})
    yield setLoadingFalse('regenerateMultisector')
    if (response.status === 200) {
      yield put(actions.setMultisectorStatus('multisector_started'))
    }
    console.log(response.data)
  } catch (error: any) {
    const errorMsg = error?.response?.message || error?.response.statusText
    console.error(errorMsg)
    yield put(actions.setError({ key: 'regenerateMultisector', value: errorMsg }))
  }
}

const fetchMultisectorsSaga = function* (action: PayloadAction<string>) {
  try {
    const userId = action.payload
    yield put(actions.setError({ key: 'multisectorsList', value: null }))
    yield setLoadingTrue('multisectorsList')
    const response: AxiosResponse<MultisectorDbRow[]> = yield call(authGet, ENDPOINTS.multisectors(userId), {})
    yield setLoadingFalse('multisectorsList')
    yield put(actions.setMultisectorsList(response.data))
  } catch (error: any) {
    yield setLoadingFalse('multisectorsList')
    yield put(actions.setError({ key: 'multisectorsList', value: error?.message }))
  }
}
const downloadPdfSaga = function* (
  action: PayloadAction<{
    searchId: string
    pdfFileName: string
  }>
) {
  try {
    yield setLoadingTrue('downloadPdf')
    const searchId = action.payload.searchId
    const response: AxiosResponse<any> = yield call(Api.getBlob, ENDPOINTS.reportPDF(searchId), {})
    const filename = action.payload.pdfFileName + '.pdf'
    yield setLoadingFalse('downloadPdf')

    blobToPdf(response.data, filename)
  } catch (error: any) {
    yield put(actions.setError({ key: 'downloadPdf', value: error?.message }))
    yield setLoadingFalse('downloadPdf')
    console.log(error)
  }
}

function blobToPdf(blob: any, reportName: any) {
  const file = new Blob([blob], { type: 'application/pdf' })
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = reportName
  link.click()
}
