import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserState, LoggedUser } from './types'
import { call, put, takeEvery } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import * as Api from 'services/api'
import { LoginFormType } from 'containers/user/types'

type LoadingKeyValueType = {
  key: keyof UserState['loading']
  value: boolean
}

const initialState: UserState = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  loggedIn: false,
  needsConfirmation: false,
  error: '',
  loading: {
    login: false,
  },
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    postLogin: (state, action: PayloadAction<any>) => {},
    setNeedsConfirmation: (state, action: PayloadAction<any>) => {
      state.needsConfirmation = true
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
    },
    setLogin: state => {
      state.loggedIn = true
      if (localStorage.getItem('user')) {
        const user: UserState = JSON.parse(localStorage.getItem('user')!)
        state.userId = user.userId
        state.firstName = user.firstName
        state.lastName = user.lastName
        state.email = user.email
        state.roles = user.roles
      }
    },
    setLoginWithToken: (state, action: PayloadAction<LoggedUser>) => {
      state.loggedIn = true
      state.userId = action.payload.userId
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.roles = action.payload.roles
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
      localStorage.setItem('user', JSON.stringify(state))
    },
    logout: state => {
      state.loggedIn = false
      state.userId = ''
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.roles = []
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user')
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
  },
})

export const actions = slice.actions

export const reducer = slice.reducer

export function* sagas() {
  yield takeEvery(actions.postLogin, postLoginSaga)
}
/**
 * Sagas
 */

const setLoadingTrue = function* (key: LoadingKeyValueType['key']) {
  yield put(actions.setLoading({ key: key, value: true }))
}

const setLoadingFalse = function* (key: LoadingKeyValueType['key']) {
  yield put(actions.setLoading({ key: key, value: false }))
}

export const postLoginSaga = function* (action: PayloadAction<LoginFormType>) {
  try {
    yield setLoadingTrue('login')
    const data = action.payload
    const response: AxiosResponse<any> = yield call(Api.postForm, Api.ENDPOINTS.login, data)
    yield setLoadingFalse('login')
    if (response.data?.status === 'pending') {
      yield put(actions.setNeedsConfirmation(data))
    } else {
      const logindata: LoggedUser = response.data
      yield put(actions.setLoginWithToken(logindata))
    }
  } catch (error: any) {
    yield setLoadingFalse('login')
    let errorMessage = 'An error occurred. Please try again.'
    if (error?.response?.status === 401) {
      errorMessage = 'The email address or password is incorrect. Please try again.'
    } else if (error?.response?.status === 403) {
      errorMessage = error.response.data ? error.response.data : 'Forbidden access'
    }
    yield put(actions.setLoginError(errorMessage))
    console.log(error)
  }
}
