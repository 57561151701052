import { Typography, TypographyProps } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

type Props = TypographyProps & {
  children: JSX.Element | JSX.Element[] | string | string[]
  variant?: Variant
}
function Title({ children, ...rest }: Props) {
  const value = rest.variant ?? 'h4'
  return (
    <Typography {...rest} variant={value}>
      {children}
    </Typography>
  )
}

export default Title
