import React, { ReactNode } from 'react'
import { StackProps, Stack, Box, IconButton, Tooltip } from '@mui/material'

import { ecolors } from 'containers/app/theme'
import InfoIcon from '@mui/icons-material/Info'

interface CustomStackProps extends StackProps {
  children?: ReactNode // Para que el componente acepte hijos como cualquier otro componente de MUI
  title: string
  titleSize?: string
}

function SectionTitle({ title, fontSize }: { title: String; fontSize?: string }) {
  const size = fontSize ? fontSize + 'px' : '28px'
  return (
    <Box component="section" sx={{ fontSize: size, color: 'primary.main' }}>
      <Stack direction="row" alignItems="center">
        {title}
        <Tooltip title="Add some info">
          <IconButton>
            <InfoIcon sx={{ color: ecolors.midgrey }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  )
}

const SectionHeader: React.FC<CustomStackProps> = ({ title, titleSize, children, ...props }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 2 }} justifyContent="space-between" {...props}>
      <SectionTitle title={title} fontSize={titleSize} />
      {children}
    </Stack>
  )
}

export default SectionHeader
