import { useEffect, useState } from 'react'
import { RootState } from 'store/'
import { useAppDispatch } from 'store/hooks'
import { actions as searchActions } from 'store/search'
import { ReportDataRequest, actions as dashboardActions } from 'store/dashboard'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import useInterval from 'use-interval'
import Spinner from 'components/spinner/Spinner'

import Report from './report/Report'
import styles from './SingleReportPage.module.scss'
import { Box, Button } from '@mui/material'
import RegenerateReportSection from './components/RegenerateReportSection'
import SearchInfoSection from './components/SearchInfoSection'

type ParamsType = {
  id: string
}

export default function SingleReportPage() {
  const dispatch = useAppDispatch()
  const roles = useSelector((state: RootState) => state.account.roles)
  const searchStatus = useSelector((state: RootState) => state.search.searchStatus)
  const searchStatusLoading = useSelector((state: RootState) => state.search.loading.searchStatus)
  const errorSearch = useSelector((state: RootState) => state.search.error)
  const reportData = useSelector((state: RootState) => state.dashboard.reportData)
  const reportDataLoading = useSelector((state: RootState) => state.dashboard.loading.reportData)
  const errorDashboard = useSelector((state: RootState) => state.dashboard.error)
  const downloadPdfLoading = useSelector((state: RootState) => state.dashboard.loading.downloadPdf)
  const [regeneratingStarted, setRegeneratingStarted] = useState<boolean>(false)
  const params = useParams<ParamsType>()
  const searchId = params?.id || ''
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const limit = queryParams.has('limit') ? parseInt(queryParams.get('limit')!) : null

  useEffect(() => {
    dispatch(searchActions.getSearchStatus(searchId))
  }, [dispatch, searchId])

  useEffect(() => {
    const getReportData = () => {
      const data: ReportDataRequest = {
        id: searchId,
        ...(limit && { limit: limit }),
      }
      dispatch(dashboardActions.fetchReportData(data))
    }

    if (searchStatus && searchStatus !== 'report_compiling') {
      getReportData()
      setRegeneratingStarted(false)
    }
  }, [dispatch, searchStatus, limit, searchId])

  useInterval(
    () => {
      dispatch(searchActions.getSearchStatus(searchId))
    },
    searchStatus === 'report_compiling' || regeneratingStarted ? 6000 : null
  )

  const downloadPdf = () => {
    const data = {
      searchId,
      pdfFileName: reportData!.search_info.name + '_' + reportData!.search_info.created_at,
    }
    dispatch(dashboardActions.downloadPdf(data))
  }

  return (
    <div className={styles.base}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>INVESTOR IP INSIGHTS REPORT</h1>
        {roles?.includes('bo_admin') && (
          <RegenerateReportSection searchId={searchId} setRegeneratingStarted={() => setRegeneratingStarted(true)} />
        )}
      </div>
      {regeneratingStarted && (
        <Box style={{ textAlign: 'center' }}>
          <h3>
            REGENERATING <Spinner size={50} />
          </h3>
        </Box>
      )}
      {searchStatus}

      {searchStatus === 'report_compiling' && <div>Report is being compiled. </div>}

      {errorSearch.searchStatus && <div className={styles.error}>{errorSearch.searchStatus}</div>}

      {reportDataLoading ||
        searchStatusLoading ||
        (searchStatus === 'report_compiling' && (
          <div className={styles.loading}>
            <Spinner size={50} />
          </div>
        ))}
      {errorDashboard.reportData && <div className={styles.error}>{errorDashboard.reportData}</div>}

      {limit && <div>Limit: {limit}</div>}

      {reportData && (
        <>
          <SearchInfoSection searchId={searchId} reportData={reportData} />
          {reportData.results_length === 0 ? (
            <>Search has no results</>
          ) : (
            <>
              <div className={styles.pdfBtn}>
                <Button
                  variant="contained"
                  onClick={downloadPdf}
                  disabled={downloadPdfLoading}
                  sx={{
                    cursor: downloadPdfLoading ? 'wait' : 'pointer',
                  }}>
                  Get PDF
                </Button>
                {errorDashboard.downloadPdf && <div className={styles.error}>{errorDashboard.downloadPdf}</div>}
              </div>
              <div className={styles.reportContainer}>
                <Report reportData={reportData} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
