import { Avatar } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

type Prop = {
  opened: Boolean
  onClick: () => void
}
export default function OpenCloseButton({ opened, onClick }: Prop) {
  return (
    <Avatar sx={{ bgcolor: 'primary.main', mx: '10px' }} onClick={onClick}>
      {opened ? <RemoveIcon sx={{ cursor: 'pointer' }} /> : <AddIcon sx={{ cursor: 'pointer' }} />}
    </Avatar>
  )
}
