import React, { useState, MouseEvent, useEffect } from 'react'

import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'

type Props = {
  id: string
  expandedId: string
  handleExpandResults: (id: string) => void
}
export default function CopyAndExpand({ id, expandedId, handleExpandResults }: Props) {
  const [clipboard, setClipboard] = useState(false)

  return (
    <Box display="flex" flexDirection={'column'} alignItems={'center'} pt={0.4}>
      <CopyToClipboard
        text={id}
        onCopy={() => {
          setClipboard(true)
          setTimeout(() => {
            setClipboard(false)
          }, 2000)
        }}>
        <Box
          sx={{
            cursor: 'pointer',
            '&:hover svg': { color: 'primary.main' },
          }}>
          <CopyIcon size="1.1rem" />
        </Box>
      </CopyToClipboard>
      {clipboard && <div>Copied!</div>}
      <Box
        sx={{
          borderRadius: 20,
          height: 25,
          cursor: 'pointer',
          '&:hover svg': { borderRadius: 20, backgroundColor: 'lightgrey', color: 'primary.main' },
        }}
        onClick={() => handleExpandResults(id)}>
        {expandedId === id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>
    </Box>
  )
}
