import React, { useState, MouseEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { UserSearch, actions as dashboardActions } from 'store/adminArea'

import styles from './SearchesList.module.scss'

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Box,
  CircularProgress,
  Checkbox,
  TablePagination,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Search, SearchFilter } from 'store/commonTypes'
import ResultTable from '../resultTable/ResultTable'
import TruncatedText from './components/TruncatedText'
import GenerateMultisectorReport from './components/GenerateMultisectorReport'
import ExportMultipleResultsExcel from './components/ExportMultipleResultsExcel'
import CopyAndExpand from './components/CopyAndExpand'

type Props = {
  userId: string
}

interface Column {
  id: keyof Search
  label: string
  minWidth?: number
  maxwidth?: number
  wordBreak?: string
  align?: 'right'
  format?: (value: any) => any
}

function SearchesList({ userId }: Props) {
  const dispatch = useAppDispatch()
  const searchesPerPage = useSelector((state: RootState) => state.adminArea.userDetails).searches
  const totalSearchesCount = useSelector((state: RootState) => state.adminArea.userDetails).totalSearchesCount
  const multisectorId = useSelector((state: RootState) => state.adminArea.multisectorId)
  const isLoading = useSelector((state: RootState) => state.adminArea.loading.searches)

  const [expandedResults, setExpandedResults] = useState('')
  const [searchesSelected, setSearchesSelected] = useState<string[]>([])
  const [multisectorIsSubmitted, setMultisectorIsSubmitted] = useState<boolean>(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  useEffect(() => {
    dispatch(dashboardActions.getUserSearches({ userId, limit: rowsPerPage, page }))
  }, [dispatch, userId, page, rowsPerPage])

  useEffect(() => {
    if (multisectorIsSubmitted && multisectorId) {
      window.open(`/dashboard/reporting/multisector/${multisectorId}`, '_blank')
    }
  }, [multisectorId])

  const handleGetResults = (searchId: string) => {
    const data: UserSearch = { userId: userId, searchId: searchId }
    dispatch(dashboardActions.getUserSearchResults(data))
  }

  const selectSearchesBetweenTwoIds = (selectedSearchId: string) => {
    const lastSearchId = searchesSelected.slice(-1)[0]
    const filteredSearches = searchesPerPage!.filter(search => !['error', 'queued', 'search_processing'].includes(search.status))
    const allSearchIds = filteredSearches!.map(search => search.ID)
    const index1 = allSearchIds.indexOf(lastSearchId)
    const index2 = allSearchIds.indexOf(selectedSearchId)

    // If one or both are not in the list we just add it
    if (index1 === -1 || index2 === -1) {
      setSearchesSelected([...searchesSelected, selectedSearchId])
    } else {
      // slice to get the elements between index1 and index2 (ambos inclusive)
      const elementosEntreIds = allSearchIds.slice(Math.min(index1, index2), Math.max(index1, index2) + 1)
      const conjuntoIds = new Set(searchesSelected)
      elementosEntreIds.forEach(id => conjuntoIds.add(id))
      const newIdsSelected = Array.from(conjuntoIds)
      setSearchesSelected(newIdsSelected)
    }
  }

  const handleCheckboxChange = (event: MouseEvent<HTMLButtonElement>, searchId: string): void => {
    if (searchesSelected.includes(searchId)) {
      setSearchesSelected(searchesSelected.filter(selected => selected !== searchId))
    } else {
      if (event.shiftKey) {
        selectSearchesBetweenTwoIds(searchId)
      } else setSearchesSelected([...searchesSelected, searchId])
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getDate = (timestamp: string) => {
    if (timestamp) {
      const d = new Date(timestamp)
      const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} `
      const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
      const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
      const time = `${d.getHours()}:${minutes}:${seconds} `
      return date + time
    } else return ''
  }

  const columns: Column[] = [
    {
      id: 'ID',
      label: '#',
      format: (id: string) => (
        <Checkbox size="small" checked={searchesSelected.includes(id)} onClick={event => handleCheckboxChange(event, id)} />
      ),
    },
    {
      id: 'ID',
      label: 'id',
      format: (id: string) => (
        <CopyAndExpand
          id={id}
          expandedId={expandedResults}
          handleExpandResults={() => {
            setExpandedResults(expandedResults === id ? '' : id)
            handleGetResults(id)
          }}
        />
      ),
    },
    { id: 'created_at', label: 'Created at', maxwidth: 90, format: (value: string) => getDate(value) },
    {
      id: 'status',
      label: 'Search status',
      format: (status: string) => {
        return status === 'complete' ? <Box color="#00ae00">{status}</Box> : <>{status}</>
      },
    },
    { id: 'name', label: 'Name', wordBreak: 'break-all', maxwidth: 300 },
    {
      id: 'text',
      label: 'Text',
      wordBreak: 'break-all',
      format: (value: string) => <TruncatedText maxwidth={300}>{value}</TruncatedText>,
    },
    {
      id: 'filter',
      label: 'Categories',
      format: (filter: SearchFilter) => <TruncatedText maxwidth={150}>{filter.classificationsIPCR?.join(', ')}</TruncatedText>,
    },
    {
      id: 'filter',
      label: 'Territories',
      format: (filter: SearchFilter) => <TruncatedText maxwidth={150}>{filter.countries?.join(', ')}</TruncatedText>,
    },

    {
      id: 'terms',
      label: 'Terms',
      wordBreak: 'break-all',
      format: (value: string) => <TruncatedText maxwidth={200}>{value}</TruncatedText>,
    },
    {
      id: 'ID',
      label: 'Report',
      format: (id: string) => (
        <Link className={styles.link} target="_blank" to={`/dashboard/reporting/report/${id}`}>
          Web report
        </Link>
      ),
    },
  ]

  const statusesWhereSearchHasFinished = ['search_finished', 'report_compiling', 'complete', 'report_error']

  return (
    <div className={styles.base}>
      <Box sx={{ textAlign: 'center', width: 1 }}>
        {isLoading && <CircularProgress />}
        {/* {error && (
            <Typography p={1} color={'red'}>
              {error}
            </Typography>
          )} */}
      </Box>
      {searchesPerPage?.length && (
        <>
          <Box display="flex" flexDirection={'column'} mb={1}>
            <Box sx={{ mr: 1 }} className={styles.optContainer}>
              <ExportMultipleResultsExcel userId={userId} searchesSelected={searchesSelected} />
            </Box>

            <Box flexGrow={1} className={styles.optContainer}>
              <GenerateMultisectorReport
                userId={userId}
                searchesSelected={searchesSelected}
                handleMultisectorSubmitted={() => setMultisectorIsSubmitted(true)}
              />
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 950 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id + column.label} align={column.align} style={{ maxWidth: column.maxwidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchesPerPage.slice(0, rowsPerPage).map(search => (
                  <React.Fragment key={search.ID}>
                    <TableRow hover role="checkbox" tabIndex={-1} key={search.ID}>
                      {columns.map(column => {
                        const value = search[column.id]
                        return (
                          <TableCell
                            key={column.id + column.label}
                            align={column.align}
                            style={{ maxWidth: column.maxwidth }}
                            sx={{ padding: '6px 8px', wordBreak: column.wordBreak ? column.wordBreak : 'normal' }}>
                            {column.label === 'Report' ? (
                              <>{statusesWhereSearchHasFinished.includes(search.status) && column.format!(value)}</>
                            ) : column.label === '#' ? (
                              <>{statusesWhereSearchHasFinished.includes(search.status) && column.format!(value)}</>
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    {expandedResults === search.ID && (
                      <TableRow>
                        <TableCell sx={{ p: 0, border: 0 }} colSpan={columns.length}>
                          {['error', 'search_processing', 'queued'].includes(search.status) ? (
                            <Box p={2}>Status {search.status}</Box>
                          ) : (
                            <ResultTable userId={userId} search={search} results={search?.results} />
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            {searchesPerPage && searchesPerPage?.length === 0 && <Stack p={2}>No searches created</Stack>}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[30, 60, 100]}
            component="div"
            count={totalSearchesCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  )
}

export default SearchesList
