import { put, takeEvery, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { User } from './types'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { blobToFile } from 'store/utils'
import { Result, Search, UserSearches } from 'store/commonTypes'

type SearchIdResults = {
  searchId: string
  results: Result[]
}

type UserSearchesPage = {
  id: string
  user?: User
  searches?: Search[]
  totalSearchesCount: number
}

type LoadingKeyValueType = {
  key: keyof AdminAreaState['loading']
  value: boolean
}

type errorKeyValueType = {
  key: keyof AdminAreaState['error']
  value: null | string
}

export type UserSearch = {
  userId: string
  searchId: string
}

export type ExportRequest = {
  userId: string
  searchId: string
  searchName: string
}

export type UserSearchesIds = {
  searchesCreatedBy: string
  searchIds: string[]
  limit?: number
  startDate?: string
  endDate?: string
  onlyProtectedPatents?: boolean
}

type AdminAreaState = {
  users: User[]
  userDetails: UserSearchesPage
  multisectorId: string | null
  loading: {
    users: boolean
    searches: boolean
    results: boolean
    export: boolean
    exportForAnalysis: { [key: string]: boolean }
    multipleResults: boolean
    generateMultisector: boolean
  }
  error: {
    user: string | null
    searches: string | null
    results: string | null
    multipleResults: string | null
    generateMultisector: string | null
  }
}

/**
 * Initial value(s) of the stores state
 */
const initialState: AdminAreaState = {
  users: [],
  userDetails: {
    id: '',
    totalSearchesCount: 0,
  },
  multisectorId: null,
  loading: {
    users: false,
    searches: false,
    results: false,
    export: false,
    exportForAnalysis: {},
    multipleResults: false,
    generateMultisector: false,
  },
  error: {
    user: null,
    searches: null,
    results: null,
    multipleResults: null,
    generateMultisector: null,
  },
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'adminArea',
  initialState,
  reducers: {
    getUsers: (state, action: PayloadAction<string>) => {},
    getUser: (state, action: PayloadAction<string>) => {},
    getUserSearches: (state, action: PayloadAction<{ userId: string; limit?: number; page?: number }>) => {},
    getUserSearchResults: (state, action: PayloadAction<UserSearch>) => {},
    exportResults: (state, action: PayloadAction<ExportRequest>) => {},
    exportResultsForAnalysis: (state, action: PayloadAction<ExportRequest>) => {},

    exportMultipleResultsExcel: (state, action: PayloadAction<UserSearchesIds>) => {},
    generateMultisector: (state, action: PayloadAction<UserSearchesIds>) => {},
    setMultisectorId: (state, action: PayloadAction<string>) => {
      state.multisectorId = action.payload
    },
    setUsers: (state, action: PayloadAction<Array<User>>) => {
      state.users = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.userDetails.id = action.payload.id
      state.userDetails.user = action.payload
    },
    setUserSearches: (state, action: PayloadAction<{ id: string; data: UserSearches }>) => {
      state.userDetails.id = action.payload.id
      state.userDetails.totalSearchesCount = action.payload.data.totalSearchesCount
      state.userDetails.searches = action.payload.data.searches
    },
    setUserSearchResult: (state, action: PayloadAction<SearchIdResults>) => {
      if (state.userDetails && state.userDetails.searches) {
        const searchIndex: number = state.userDetails.searches.findIndex(search => search.ID === action.payload.searchId)
        state.userDetails.searches[searchIndex].results = action.payload.results
      }
    },
    setError: (state, action: PayloadAction<errorKeyValueType>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      if (key !== 'exportForAnalysis') state.loading[key] = value
    },
    setExportLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.export = action.payload
    },
    setExportForAnalysisLoading: (state, action: PayloadAction<{ searchId: string; value: boolean }>) => {
      state.loading.exportForAnalysis[action.payload.searchId] = action.payload.value
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.getUsers, getUsersSaga)
  yield takeEvery(actions.getUser, getUserSaga)
  yield takeEvery(actions.getUserSearches, getUserSearchesSaga)
  yield takeEvery(actions.getUserSearchResults, getUserSearchResultsSaga)
  yield takeEvery(actions.exportResults, exportResultsSaga)
  yield takeEvery(actions.exportResultsForAnalysis, exportResultsForAnalysisSaga)
  yield takeEvery(actions.generateMultisector, generateMultisectorSaga)
  yield takeEvery(actions.exportMultipleResultsExcel, exportMultipleResultsExcelSaga)
}

/**
 * Sagas
 */

const setLoadingTrue = function* (key: LoadingKeyValueType['key']) {
  yield put(actions.setLoading({ key: key, value: true }))
}

const setLoadingFalse = function* (key: LoadingKeyValueType['key']) {
  yield put(actions.setLoading({ key: key, value: false }))
}

export const getUsersSaga = function* (action: PayloadAction<string>) {
  try {
    yield setLoadingTrue('users')
    const productName = action.payload
    const response: AxiosResponse<User[]> = yield call(Api.authGet, `${Api.ENDPOINTS.users}/?product=${productName}`, {})
    yield setLoadingFalse('users')
    yield put(actions.setUsers(response.data))
  } catch (error: any) {
    yield setLoadingFalse('users')
    console.log(error)
  }
}

export const getUserSaga = function* (action: PayloadAction<string>) {
  try {
    const userId = action.payload
    const response: AxiosResponse<User> = yield call(Api.authGet, Api.ENDPOINTS.user(userId), {})
    const user: User = response.data
    user.id = userId

    yield put(actions.setUser(user))
  } catch (error: any) {
    yield put(actions.setError({ key: 'user', value: error?.message }))
    console.log(error)
  }
}

export const getUserSearchesSaga = function* (action: PayloadAction<{ userId: string; limit?: number; page?: number }>) {
  const userId = action.payload.userId
  const offset = action.payload.page
  const limit = action.payload.limit
  const body = {
    ...(offset !== undefined && { offset }),
    ...(limit !== undefined && { limit }),
  }

  yield put(actions.setError({ key: 'searches', value: null }))
  yield setLoadingTrue('results')
  yield put(actions.setLoading({ key: 'searches', value: true }))
  try {
    const response: AxiosResponse<UserSearches> = yield call(Api.authGet, Api.ENDPOINTS.userSearches(userId), body)
    yield setLoadingFalse('searches')
    yield put(actions.setUserSearches({ id: userId, data: response.data }))
  } catch (error: any) {
    yield setLoadingFalse('searches')
    yield put(actions.setError({ key: 'searches', value: error?.message }))
    console.log(error)
  }
}

export const getUserSearchResultsSaga = function* (action: PayloadAction<UserSearch>) {
  yield put(actions.setError({ key: 'results', value: null }))
  yield setLoadingTrue('results')

  try {
    const response: AxiosResponse<Result[]> = yield call(
      Api.authGet,
      Api.ENDPOINTS.userSearchResults(action.payload.userId, action.payload.searchId),
      { limit: 50 }
    )
    const data: SearchIdResults = {
      searchId: action.payload.searchId,
      results: response.data,
    }

    yield setLoadingFalse('results')
    yield put(actions.setUserSearchResult(data))
  } catch (error: any) {
    yield put(actions.setError({ key: 'results', value: error?.message }))
    yield setLoadingFalse('results')
    console.log(error)
  }
}

export const exportResultsSaga = function* (action: PayloadAction<ExportRequest>) {
  try {
    yield put(actions.setExportLoading(true))

    const data = { format: 'csv', numResults: 250 }
    const response: AxiosResponse<any> = yield call(
      Api.authPost,
      Api.ENDPOINTS.exportResults(action.payload.userId, action.payload.searchId),
      data
    )

    yield put(actions.setExportLoading(false))

    const filename = action.payload.searchName
    blobToFile(response.data, filename, data.format)
  } catch (error: any) {
    yield put(actions.setExportLoading(false))
    console.log(error)
  }
}

export const exportResultsForAnalysisSaga = function* (action: PayloadAction<ExportRequest>) {
  const searchId = action.payload.searchId
  yield put(actions.setExportForAnalysisLoading({ searchId, value: true }))
  try {
    const body = {
      format: 'csv',
      numResults: 2600,
    }
    const response: AxiosResponse<any> = yield call(
      Api.authPost,
      Api.ENDPOINTS.exportResultsForAnalysis(action.payload.userId, searchId),
      body
    )

    yield put(actions.setExportForAnalysisLoading({ searchId, value: false }))

    const filename = action.payload.searchName
    blobToFile(response.data, filename, body.format)
  } catch (error: any) {
    yield put(actions.setExportForAnalysisLoading({ searchId, value: false }))
    console.log(error)
  }
}

export const generateMultisectorSaga = function* (action: PayloadAction<UserSearchesIds>) {
  yield put(actions.setLoading({ key: 'generateMultisector', value: true }))
  yield put(actions.setError({ key: 'generateMultisector', value: null }))
  try {
    const response: AxiosResponse<string> = yield call(Api.authPost, Api.ENDPOINTS.generateMultisector, action.payload)
    yield put(actions.setMultisectorId(response.data))

    yield setLoadingFalse('generateMultisector')
  } catch (error: any) {
    yield setLoadingFalse('generateMultisector')
    yield put(actions.setError({ key: 'generateMultisector', value: error?.message }))
    console.log(error)
  }
}

export const exportMultipleResultsExcelSaga = function* (action: PayloadAction<UserSearchesIds>) {
  yield setLoadingTrue('multipleResults')
  yield put(actions.setError({ key: 'multipleResults', value: null }))

  try {
    const body = {
      searchIds: action.payload.searchIds,
      limit: action.payload.limit && action.payload.limit > 0 ? action.payload.limit : 1000,
    }
    const response: AxiosResponse<any> = yield call(
      Api.authPostBlob,
      Api.ENDPOINTS.exportMultipleResults(action.payload.searchesCreatedBy),
      body
    )
    blobToFile(response.data, 'multiple-results.xls', 'excel')
    yield setLoadingFalse('multipleResults')
  } catch (error: any) {
    yield setLoadingFalse('multipleResults')
    yield put(actions.setError({ key: 'multipleResults', value: error?.message }))
    console.log(error)
  }
}
