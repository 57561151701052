import { ReactComponent as Logo } from 'assets/images/logo_ids_white_nobg.svg'
import styles from './LastPage.module.scss'
import { SearchInfo } from 'store/dashboard/types'

type Props = {
  searchInfo: SearchInfo
}
const LastPage = ({ searchInfo }: Props) => {
  return (
    <div className={`${styles.base}`}>
      <div className={styles.text}>
        <div>
          Copyright Elute Intelligence Limited 2022. This report is to be used solely by the individual that initiated the search.
        </div>
        <div>
          <a href="https://www.elute.io/">www.elute.io</a>
        </div>
      </div>
      <div className={styles.footer}>
        <Logo className={styles.logo} />
      </div>
    </div>
  )
}

export default LastPage
