import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import { IoCaretBackOutline as BackArrow } from 'react-icons/io5'
import MultisectorsList from 'containers/tables/multisectorsList/MultisectorsList'
import styles from './UserMultisectorsPage.module.scss'
import { Box } from '@mui/material'

//Admin
const UserMultisectorsPage = () => {
  const { id } = useParams<string>()
  const dispatch = useAppDispatch()
  const userDetails = useSelector((state: RootState) => state.adminArea.userDetails)
  const user = userDetails.id === id ? userDetails.user : null

  useEffect(() => {
    if (id) {
      dispatch(dashboardActions.getUser(id))
    }
  }, [dispatch, id])

  return (
    <>
      {id && (
        <div className={styles.base}>
          <div className={styles.header}>
            <span className={styles.goback}>
              <Link to="/settings/admin">
                <BackArrow size="1.1em" />
                Back
              </Link>
            </span>
            <div className={styles.title}>
              Multisectors for {user?.firstName} {user?.lastName}
            </div>
          </div>
          <Box alignItems="center" gap={2} m={4}>
            <MultisectorsList userId={id} />
          </Box>
        </div>
      )}
    </>
  )
}

export default UserMultisectorsPage
