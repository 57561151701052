import React from 'react'
import { ReactComponent as Logo } from 'assets/images/insights_ids_white_logo.svg'
import { ReactComponent as CircleLogo } from 'assets/images/insights_ids_circle_logo.svg'
import { Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import TableViewIcon from '@mui/icons-material/TableView'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {
  List,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  styled,
  Box,
  Typography,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}))

export default function Sidebar() {
  const roles = useSelector((state: RootState) => state.account.roles)
  const [open, setOpen] = React.useState(true)
  const drawerWidth = 250
  const location = useLocation()

  const menuItems = [
    { text: 'My dashboard', path: '/dashboard/reporting', icon: <DashboardOutlinedIcon /> },
    { text: 'Create new report', path: '/report', icon: <EditNoteIcon /> },
    { text: 'My multisector reports', path: '/dashboard/reporting/multisector', icon: <TableViewIcon /> },
    { text: 'My single reports', path: '/dashboard/reporting/report', icon: <ArticleOutlinedIcon /> },
    { text: 'Company Search', path: '/company/search', icon: <QueryStatsIcon /> },
  ]

  const drawerBgColor = 'rgb(18, 22, 33)'
  const drawerTxtColor = 'rgb(179, 185, 198)'
  const drawerActiveTxtColor = 'white'

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    color: drawerTxtColor,
    backgroundColor: drawerBgColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  })

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    color: drawerTxtColor,
    backgroundColor: drawerBgColor,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 17px)`,
    },
  })

  const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }))

  const MyItem = (props: { path: string; text: string; icon?: JSX.Element }) => {
    const isActive = location.pathname === props.path
    return (
      <ListItem key={props.path} disablePadding sx={{ py: 0.5 }}>
        <ListItemButton
          component={Link}
          to={props.path}
          sx={[
            {
              minHeight: 48,
              backgroundColor: isActive ? 'primary.main' : 'transparent',
              color: isActive ? drawerActiveTxtColor : drawerTxtColor,
              borderRadius: 3,
              '&:hover': {
                backgroundColor: isActive ? 'primary.main' : 'rgba(255, 255, 255, 0.04)',
                color: drawerActiveTxtColor,
                '& .MuiListItemIcon-root': {
                  color: drawerActiveTxtColor,
                },
              },
            },
          ]}>
          <ListItemIcon
            sx={[
              {
                color: isActive ? drawerActiveTxtColor : drawerTxtColor,
                minWidth: 0,
                justifyContent: 'center',
              },
              open ? { mr: 1 } : { mr: 'auto' },
            ]}>
            {props.icon && props.icon}
          </ListItemIcon>

          <ListItemText
            primaryTypographyProps={{ fontSize: '13px' }}
            primary={props.text}
            sx={[open ? { opacity: 1 } : { opacity: 0 }]}
          />
        </ListItemButton>
      </ListItem>
    )
  }
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(!open)}>
          <MenuIcon
            fontSize="large"
            sx={{
              pl: 1,
              color: drawerTxtColor,
              '&:hover': {
                color: drawerActiveTxtColor,
              },
            }}
          />
        </IconButton>
        {open && <Logo height={'50'} width={'100%'} />}
      </DrawerHeader>
      {/* <DrawerHeader>
        {open && (
          <Box height={70} sx={{ p: 0.5 }}>
            <Link to="/">
         
              <Logo width={'100%'} height={'100%'} />
            </Link>
          </Box>
        )}
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ChevronLeftIcon sx={{ color: drawerTxtColor }} /> : <ChevronRightIcon sx={{ color: drawerTxtColor }} />}
        </IconButton>
      </DrawerHeader> */}

      <Divider />
      <Box sx={{ px: 1.5, py: 2 }}>
        {/* <Typography sx={[{ pl: 1.5,  fontWeight: 'bold' }, open ? { opacity: 1 } : { opacity: 0 }]}>
          {account.firstName} {account.lastName}
        </Typography> */}
        <List>
          {menuItems.map(item => {
            return <MyItem key={item.text} path={item.path} text={item.text} icon={item.icon} />
          })}
          {roles?.includes('bo_admin') && (
            <>
              <Divider sx={{ m: 2, borderColor: drawerTxtColor }} />
              <MyItem path="/settings/admin" text="Admin Area" icon={<AdminPanelSettingsIcon />} />
            </>
          )}
        </List>
        <Divider />
      </Box>
    </Drawer>
  )
}
