import { styled } from '@mui/material'

interface TruncatedTextProps {
  maxwidth?: number | string
}

const TruncatedText = styled('div', {
  shouldForwardProp: prop => prop !== 'width',
})<TruncatedTextProps>(({ maxwidth }: TruncatedTextProps) => ({
  maxWidth: maxwidth,
  height: 40,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
}))

export default TruncatedText
