import { colors } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export const ecolors = {
  midgrey: colors.grey[400],
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#5271ff',
    },
    background: {
      default: 'rgb(246, 246, 246)',
    },
    text: {
      primary: 'rgb(33, 38, 54)',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          letterSpacing: '0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
        },
      },
    },

    MuiStack: { defaultProps: { useFlexGap: true } },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#212636',
          overflow: 'hidden',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: '#f9fafb',
          color: '#667085',
          lineHeight: '1',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #dcdfe4',
          fontSize: '0.750rem',
          fontWeight: '400',
          lineHeight: '1.10',
        },
        head: {
          backgroundColor: '#f9fafb',
          color: '#667085',
          fontWeight: '500',
          lineHeight: '1.15',
        },
      },
    },
  },
})

export default theme
