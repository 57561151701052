import React from 'react'

import styles from './Button.module.scss'

export enum STYLE {
  small = 'small',
  green = 'green',
  green2 = 'green2',
  orange = 'orange',
  white = 'white',
  darkBlue = 'darkBlue',
}

type ButtonProps = {
  title: string
  type?: 'submit' | 'button'
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  secondary?: boolean
  loading?: boolean
  big?: boolean
  style?: STYLE
  subtitle?: string
  tooltip?: string
}

function Button({
  title,
  type = 'button',
  onClick,
  disabled = false,
  secondary = false,
  loading = false,
  big = false,
  style,
  subtitle,
  tooltip,
}: ButtonProps) {
  return (
    <button
      className={`${styles.base} ${secondary ? styles.secondary : styles.primary} ${loading ? styles.loading : ''} ${
        big ? styles.big : ''
      } ${style ? styles[style] : ''}`}
      type={type}
      onClick={onClick}
      title={tooltip}
      disabled={disabled}>
      {title}
      {subtitle && <div className={styles.subtitle}> {subtitle} </div>}
    </button>
  )
}

export default Button
