import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { Applicant, MultisectorData, ThirdPartyData } from 'store/dashboard/types'
import { useEffect, useState } from 'react'
import { MultisectorStatus } from 'store/dashboard'
import NorthIcon from '@mui/icons-material/North'
import LinkIcon from '@mui/icons-material/Link'
import { Box, Button, Collapse, Divider, FormControl, Link, MenuItem, Select, Stack, Tooltip } from '@mui/material'
import { ecolors } from 'containers/app/theme'
import OpenCloseButton from './components/OpenCloseButton'
import SectionContainer from './components/SectionContainer'
import SectionHeader from './components/SectionHeader'
import SectionSubTitle from './components/SectionSubtitle'

type Props = {
  index: number
  subgroupCode: string
  data: MultisectorData
  thirdPartyDataList: ThirdPartyData[]
  status: MultisectorStatus
}

type SectorData = {
  company: string
  ipFootprint: string | number
  ipSimilarity: number
  ipWeight: any
  revenue: number
  revPerEmployee: any
  ticker: string
  website: string
}

interface Column {
  id: keyof SectorData
  label: string
  minWidth?: number
  align?: 'left' | 'center'
  format?: (value: any) => any
}

export default function SectorSection({ index, subgroupCode, data, thirdPartyDataList, status }: Props) {
  //Results refinement (filteredMultisectorList)
  // Remove result if “whales_patent_in_percent” is zero
  // Remove result if research = TRUE
  // Remove result if whales_patents_in_categories is <= 3
  const filteredMultisectorList = data.multisector.filter(
    app => app.whales_patent_in_percent > 0 && !app.research && app.whales_patents_in_categories > 3
  )
  const subgroupInfo = data.info.subgroups_info.find(sub => sub.code === subgroupCode)
  const subgroupTitle = subgroupInfo?.claude_main_title || subgroupInfo?.text || subgroupCode
  //const subgroupTitle = subgroupInfo?.claude_main_title ? subgroupInfo?.claude_main_title : subgroupCode
  const sectorTitle = 'Sector ' + index + ': ' + subgroupTitle
  const multisectorDataBySector = filteredMultisectorList.filter(app => app.subgroup === subgroupCode)
  const [sectionOpened, setSectionOpened] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [sectorDataList, setSectorDataList] = useState<SectorData[]>()

  useEffect(() => {
    const sectorList = getSectorData(multisectorDataBySector, thirdPartyDataList)
    setSectorDataList(sectorList)
  }, [multisectorDataBySector, thirdPartyDataList, status])

  const getRevPerEmployee = (tpd: ThirdPartyData) => {
    if (tpd && tpd.revenue && tpd.employee) return (tpd.revenue / tpd.employee / 1000).toFixed()
    else return '-'
  }
  const getWeightData = (value: number) => {
    if (value && value > 0) return value
    else return '-'
  }

  const getFootprint = (score: string | number) => {
    if (typeof score == 'string') return score
    else return score.toFixed()
  }

  const getSectorData = (multisectorDataBySector: Applicant[], thirdPartyDataList: ThirdPartyData[]) => {
    const sectorDataList: SectorData[] = multisectorDataBySector.map(applicant => {
      const tpd = thirdPartyDataList.filter(tpd => tpd.applicant_cleaned === applicant.applicant_cleaned)[0]

      const sd: SectorData = {
        company: applicant.applicant_original_name,
        ipFootprint: getFootprint(applicant.comparison_score),
        ipSimilarity: Number(applicant.intersection_score_sum.toFixed()),

        ipWeight: getWeightData(applicant.whales_patents_in_categories),
        revenue: tpd && Number((tpd.revenue / 1000).toFixed(2)),
        revPerEmployee: getRevPerEmployee(tpd),
        ticker: tpd && tpd.ticker,
        website: tpd && tpd.company_domain,
      }
      return sd
    })
    return sectorDataList
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getKey = (item: SectorData) => subgroupCode + item.company

  const columns: Column[] = [
    { id: 'company', label: 'Company' },
    { id: 'ipFootprint', label: 'IP Footprint' },
    { id: 'ipSimilarity', label: 'IP Similarity' },

    { id: 'ipWeight', label: 'IP Weight' },
    { id: 'revenue', label: 'Revenue $`000' },
    { id: 'revPerEmployee', label: 'Revenue per Employee $`000' },
    { id: 'ticker', label: 'Ticker' },
    {
      id: 'website',
      label: 'Website',
      align: 'center',
      format: value => {
        return (
          <Link href={'http://' + value} target="_blank">
            <LinkIcon sx={{ fontSize: 12 }} />
          </Link>
        )
      },
    },
  ]

  const Header = () => {
    return (
      <SectionHeader title="Top 100">
        <Stack direction="row" alignItems="center">
          <FormControl size="small">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={10} sx={{ color: 'primary.main' }}>
              <MenuItem value={10}>Data Feed - Level 1</MenuItem>
              <MenuItem value={20}>opt</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={10} sx={{ color: 'primary.main' }}>
              <MenuItem value={10}>Multi-Sector Report Long Quoted View</MenuItem>
              <MenuItem value={20}>seconds</MenuItem>
            </Select>
          </FormControl>
          <OpenCloseButton onClick={() => setSectionOpened(!sectionOpened)} opened={sectionOpened} />
        </Stack>
      </SectionHeader>
    )
  }

  return (
    <SectionContainer>
      {Header()}
      <Collapse in={sectionOpened}>
        <Paper variant="outlined" sx={{ borderRadius: '20px', m: 3, mt: 0 }}>
          <SectionSubTitle title={sectorTitle}>
            {subgroupInfo?.claude_description && (
              <Stack sx={{ color: ecolors.midgrey, fontSize: '12px' }}>
                <Tooltip title={subgroupInfo?.claude_description + ` (${subgroupInfo?.code})`}>
                  <Button sx={{ textTransform: 'none', color: '#848fc4' }}>Detailed description</Button>
                </Tooltip>
              </Stack>
            )}
          </SectionSubTitle>
          <Divider />
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                      {column.id === 'ipFootprint' && <NorthIcon fontSize="inherit" />}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sectorDataList &&
                  sectorDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={getKey(row)}>
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align} sx={{ padding: '11px 16px' }}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            {multisectorDataBySector.length === 0 && (
              <Box sx={{ m: 2 }}>
                No results. Reasons: “whales_patent_in_percent” is zero, ‘whales_categories_count’ is less than 2 or research is
                TRUE. Check multisector table bellow to validate the reason.
              </Box>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[15, 30, 100]}
            component="div"
            count={multisectorDataBySector.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Collapse>
    </SectionContainer>
  )
}
