import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Title from 'components/title/Title'
export default function Dashboard() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      // alignItems="center"
      gap={2}
      borderRadius={2}
      p={5} // Padding de 40px (equivale a p={5} en MUI, porque 1 unidad = 8px)
      m={4}>
      <Title>Dashboard</Title>
      <Box display="flex" flexDirection="column" gap={2}>
        <Button variant="contained" color="primary" component={Link} to="/dashboard/reporting/multisector">
          Multisectors list
        </Button>
        <Button variant="contained" color="secondary" component={Link} to="/dashboard/reporting/report">
          Single search reports list
        </Button>
      </Box>
    </Box>
  )
}
