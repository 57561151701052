import MultiSelect from 'components/multiselect/MultiSelect'
import styles from './CountryPicker.module.scss'
import './CountryStyles.css'

type Props = {
  onSelectChange: Function
  error?: boolean
}

const options = [
  { id: 'AT', name: 'Austria' },
  { id: 'BE', name: 'Belgium' },
  { id: 'CA', name: 'Canada' },
  { id: 'CH', name: 'Switzerland' },
  { id: 'CN', name: 'China' },
  { id: 'DE', name: 'Germany' },
  { id: 'DK', name: 'Denmark' },
  { id: 'EP', name: 'European Patent' },
  { id: 'ES', name: 'Spain' },
  { id: 'FI', name: 'Finland' },
  { id: 'FR', name: 'France' },
  { id: 'GB', name: 'Great Britain' },
  { id: 'JP', name: 'Japan', disabled: true },
  { id: 'KR', name: 'South Korea', disabled: true },
  { id: 'NL', name: 'Netherlands' },
  { id: 'NO', name: 'Norway' },
  { id: 'RU', name: 'Russia', disabled: true },
  { id: 'SE', name: 'Sweden' },
  { id: 'TW', name: 'Taiwan', disabled: true },
  { id: 'US', name: 'United States' },
  { id: 'WO', name: 'WIPO' },
]

function CountryPicker({ onSelectChange, error }: Props) {
  return (
    <div className={styles.base}>
      <MultiSelect
        options={options}
        onSelectChange={onSelectChange}
        title="Select one or more territories"
        optionName="territory"
        optionNamePlural="territories"
        preselectAllOptions={true}
        error={error}
      />
    </div>
  )
}

export default CountryPicker
