import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'

import { Box, Checkbox, Button, TextField, CircularProgress, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

type Props = {
  userId: string
  searchesSelected: string[]
  handleMultisectorSubmitted: () => void
}

export default function GenerateMultisectorReport({ userId, searchesSelected, handleMultisectorSubmitted }: Props) {
  const MIN_START_DATE = '2012-01-01'
  const dispatch = useAppDispatch()
  const isMultisectorLoading = useSelector((state: RootState) => state.adminArea.loading.generateMultisector)
  const [startDate, setStartDate] = useState<Date | null>(new Date(MIN_START_DATE))
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const [limitMultisector, setLimitMultisector] = useState<number>(2600)
  const [activePatents, setActivePatents] = useState(false)
  const multisectorError = useSelector((state: RootState) => state.adminArea.error.generateMultisector)

  const generateMultisector = () => {
    handleMultisectorSubmitted()
    dispatch(
      dashboardActions.generateMultisector({
        searchesCreatedBy: userId,
        searchIds: searchesSelected,
        startDate: startDate ? startDate.toISOString().split('T')[0] : '',
        endDate: endDate ? endDate.toISOString().split('T')[0] : '',
        limit: limitMultisector,
        onlyProtectedPatents: activePatents,
      })
    )
  }

  return (
    <>
      <Box display="flex" mr={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Start date'}
            views={['month', 'year']}
            minDate={new Date('1960-01-01')}
            maxDate={new Date()}
            value={startDate}
            onChange={newValue => {
              setStartDate(newValue)
            }}
            sx={{
              input: {
                p: 1.4,
                width: 120,
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box display="flex" mr={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'End date'}
            views={['month', 'year']}
            minDate={new Date('2012-03-01')}
            maxDate={new Date()}
            value={endDate}
            onChange={newValue => {
              setEndDate(newValue)
            }}
            sx={{
              input: {
                p: 1.4,
                width: 120,
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      <TextField
        id="limit"
        name="limit"
        label="Limit"
        value={limitMultisector}
        sx={{ mr: 3, input: { p: 1.4, width: 60 } }}
        onChange={event => {
          setLimitMultisector(Number(event?.target.value))
        }}
      />

      <div>
        <label>Only active patents</label>
        <Checkbox size="small" checked={activePatents} onChange={event => setActivePatents(event.target.checked)} />
      </div>
      <Button
        variant="contained"
        size="small"
        onClick={generateMultisector}
        disabled={searchesSelected.length === 0 || isMultisectorLoading}>
        Generate multisector report {isMultisectorLoading && <CircularProgress sx={{ ml: 1 }} size="20px" color="inherit" />}
      </Button>

      {multisectorError && <Typography color="red">{multisectorError}</Typography>}
    </>
  )
}
