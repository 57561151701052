import { Box, Collapse, Paper, Stack } from '@mui/material'
import { useState } from 'react'
import OpenCloseButton from './OpenCloseButton'
import { ReportData } from 'store/dashboard/types'

type Props = {
  searchId: string
  reportData: ReportData
}

export default function SearchInfoSection({ searchId, reportData }: Props) {
  const [sectionOpened, setSectionOpened] = useState(false)
  const searchInfo = reportData.search_info

  return (
    reportData && (
      <Paper variant="outlined" sx={{ my: 2.5, p: 2, bgcolor: '#f6f8fa', borderRadius: '10px', borderColor: '#8a8a8a' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{ color: 'primary.main' }}>Search info for {searchInfo.name}</Box>
          <OpenCloseButton onClick={() => setSectionOpened(!sectionOpened)} opened={sectionOpened} />
        </Stack>

        <Collapse in={sectionOpened}>
          <Box sx={{ pt: 2 }}>
            <Box>Name: {searchInfo.name}</Box>
            <Box>Created at: {searchInfo.created_at}</Box>
            <Box>Classifications: {searchInfo.filter_classifications_ipcr.join(', ')}</Box>
            <Box>Countries: {searchInfo.countries.join(', ')}</Box>
          </Box>
        </Collapse>
      </Paper>
    )
  )
}
