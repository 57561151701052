import { put, takeEvery, takeLatest, call } from 'redux-saga/effects'
import { AxiosResponse, AxiosError } from 'axios'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

let suggestionRequestController = new AbortController()

type companiesSuggestedResponse = {
  suggestedCompanies: string[]
}

type CompanyState = {
  suggestions: string[]
  searchResponse: SearchResponse | null
  status: string | null
  loading: {
    suggestions: boolean
    search: boolean
  }
  error: {
    suggestions: string | null
    search: string | null
    searchStatus: string | null
  }
}

const initialState: CompanyState = {
  suggestions: [],
  searchResponse: null,
  status: null,

  loading: {
    suggestions: false,
    search: false,
  },
  error: {
    suggestions: null,
    search: null,
    searchStatus: null,
  },
}

type SearchResponse = {
  ID: string
  created_by: string
  company_names: string
  search_ids: string
  status: string
  logs: string[]
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    getSuggestedCompanies: (state, action: PayloadAction<string>) => {},
    setSuggestedCompanies: (state, action: PayloadAction<Array<string>>) => {
      state.suggestions = action.payload
    },
    submitCompanySearch: (state, action: PayloadAction<string[]>) => {},
    setSearchReponse: (state, action: PayloadAction<SearchResponse>) => {
      state.searchResponse = action.payload
    },
    getStatus: (state, action: PayloadAction<string | null>) => {},
    setStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload.status
    },
    setLoading: (state, action: PayloadAction<{ key: 'suggestions' | 'search'; value: boolean }>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
    setError: (state, action: PayloadAction<{ key: 'suggestions' | 'search' | 'searchStatus'; value: string }>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.getSuggestedCompanies, getSuggestedCompaniesSaga)
  yield takeEvery(actions.submitCompanySearch, submitSearchSaga)
  yield takeLatest(actions.getStatus, getStatusSaga)
}

const GENERAL_ERROR = 'Something went wrong, please try again. \nIf the problem continues, please contact us.'
/**
 * Sagas
 */
export const getSuggestedCompaniesSaga = function* (action: PayloadAction<string>) {
  const startWith = action.payload
  yield put(actions.setLoading({ key: 'suggestions', value: true }))

  try {
    // yield call(suggestionRequestController.abort) // cancel any previous API calls
    suggestionRequestController.abort() // cancel any previous API calls

    suggestionRequestController = new AbortController()

    const response: AxiosResponse<companiesSuggestedResponse> = yield call(
      Api.authGet,
      `${Api.ENDPOINTS.companySuggestions}`,
      {
        starts_with: startWith,
      },
      suggestionRequestController.signal
    )

    yield put(actions.setLoading({ key: 'suggestions', value: false }))
    yield put(actions.setSuggestedCompanies(response.data.suggestedCompanies))
  } catch (error: any) {
    if (error.code !== 'ERR_CANCELED') {
      yield put(actions.setLoading({ key: 'suggestions', value: false }))
      yield put(actions.setError({ key: 'suggestions', value: GENERAL_ERROR }))
      console.log(error)
    }
  }
}

export const submitSearchSaga = function* (action: PayloadAction<string[]>) {
  yield put(actions.setLoading({ key: 'search', value: true }))
  try {
    const response: AxiosResponse<SearchResponse> = yield call(Api.authPostForm, Api.ENDPOINTS.companySearch, {
      company_names: action.payload,
    })
    yield put(actions.setLoading({ key: 'search', value: false }))

    if (response.data?.ID) {
      yield put(actions.setSearchReponse(response.data))
    } else {
      //TODO: if no company search returned???
    }
  } catch (error: any) {
    yield put(actions.setLoading({ key: 'search', value: false }))
    const err = error as AxiosError
    const errorMessage =
      err?.response?.status === 404 ? "Can't find provided search ID. Please check if the URL is correct." : GENERAL_ERROR
    yield put(actions.setError({ key: 'search', value: errorMessage }))
    console.log(error)
  }
}

const getStatusSaga = function* (action: any) {
  const searchId = action.payload
  try {
    const response: AxiosResponse<any> = yield call(Api.authPost, Api.ENDPOINTS.companySearchStatus(searchId), {})
    // const status: any = {
    //   status: response.data.status,
    // }

    // yield put(actions.setStatus(status))
    yield put(actions.setSearchReponse(response.data))
  } catch (error) {
    yield put(actions.setError({ key: 'searchStatus', value: GENERAL_ERROR }))
    console.log(error)
  }
}
