import React, { ReactNode } from 'react'
import { StackProps, Stack, Typography } from '@mui/material'

interface CustomStackProps extends StackProps {
  children?: ReactNode // Para que el componente acepte hijos como cualquier otro componente de MUI
  title: string
}

const SectionSubTitle: React.FC<CustomStackProps> = ({ title, children, ...props }) => {
  return (
    <Stack direction="row" alignItems="center" p={1.2}>
      <Typography sx={{ px: '10px', fontSize: '18px', color: 'primary.main' }}>{title}</Typography>
      {children}
    </Stack>
  )
}

export default SectionSubTitle
