import { TextField, Button, Box } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { RootState, useAppDispatch } from 'store'
import { ReportDataRequest, actions as dashboardActions } from 'store/dashboard'

type Props = {
  searchId: string
  setRegeneratingStarted: () => void
}

export default function RegenerateReportSection({ searchId, setRegeneratingStarted }: Props) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const DATE_MIN = 1700
  const [yearCutoff, setYearCutoff] = useState<number>(DATE_MIN)
  const queryParams = new URLSearchParams(location.search)
  const limit = queryParams.has('limit') ? parseInt(queryParams.get('limit')!) : null
  const regenerateDataLoading = useSelector((state: RootState) => state.dashboard.loading.regenerateMultisector)

  const regenerateReportData = () => {
    setRegeneratingStarted()
    let _yearCutoff = yearCutoff
    if (_yearCutoff < DATE_MIN || isNaN(_yearCutoff)) {
      setYearCutoff(DATE_MIN)
      _yearCutoff = DATE_MIN
    }

    const data: ReportDataRequest = {
      id: searchId,
      regenerate: true,
      yearCutoff: _yearCutoff,
      ...(limit && { limit: limit }),
    }
    dispatch(dashboardActions.fetchReportData(data))
  }
  return (
    <Box m={2} p={2} sx={{ border: '1px solid rgb(220, 220, 220)', borderRadius: 5 }}>
      <TextField
        id="cutoffyearid"
        label="Cutoff year"
        value={yearCutoff}
        onChange={ev => setYearCutoff(Number(ev?.target.value))}
        variant="outlined"
        size="small"
        sx={{ width: 100, mr: 2 }}
      />

      <Button
        variant="contained"
        onClick={regenerateReportData}
        disabled={regenerateDataLoading}
        sx={{
          cursor: regenerateDataLoading ? 'wait' : 'pointer',
          maxHeight: 80,
        }}>
        Regenerate report data (bo admin)
      </Button>
    </Box>
  )
}
