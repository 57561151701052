import ApplicantCard from './ApplicantCard'
import { Box, Collapse, FormControl, IconButton, MenuItem, Select, Stack, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid2'
import InfoIcon from '@mui/icons-material/Info'
import { Applicant, MultisectorData, ThirdPartyData } from 'store/dashboard/types'
import { ecolors } from 'containers/app/theme'
import OpenCloseButton from '../components/OpenCloseButton'
import { useState } from 'react'
import SectionContainer from '../components/SectionContainer'
import SectionHeader from '../components/SectionHeader'

type Props = {
  data: MultisectorData
  thirdPartyDataList: ThirdPartyData[] | null
  subgroups: string[]
}

interface GroupedApplicant {
  applicant_cleaned: string
  subgroups_data: Applicant[]
}

function groupApplicantsByApplicantCleaned(applicants: Applicant[], subgroupsSelected: string[]): GroupedApplicant[] {
  // Usar un objeto como mapa para agrupar por `applicant_cleaned`
  const groupedApplicants: { [key: string]: Applicant[] } = {}

  // Recorrer la lista de solicitantes y agrupar por `applicant_cleaned`
  applicants.forEach(applicant => {
    if (subgroupsSelected.includes(applicant.subgroup)) {
      const key = 'clean_' + applicant.applicant_cleaned.toString()
      if (!groupedApplicants[key]) {
        groupedApplicants[key] = []
      }
      groupedApplicants[key].push(applicant)
    }
  })

  // Transformar el objeto agrupado en un array de `GroupedApplicant`
  return Object.keys(groupedApplicants).map(key => ({
    applicant_cleaned: key,
    subgroups_data: groupedApplicants[key],
  }))
}
export default function TopResultsSection({ subgroups, data, thirdPartyDataList }: Props) {
  const getKey = (app: Applicant) => app.applicant_original_name + app.comparison_score + app.subgroup
  const [sectionOpened, setSectionOpened] = useState(true)
  const groupByCleanName = groupApplicantsByApplicantCleaned(data.multisector, subgroups)

  function Header() {
    return (
      <SectionHeader title="Top 20: Chosen sectors">
        <Stack direction="row" alignItems="center">
          <Box sx={{ color: ecolors.midgrey, marginRight: '15px', fontSize: '12px' }}>
            Ikon key
            <Tooltip title="Ikon description">
              <IconButton>
                <InfoIcon sx={{ color: ecolors.midgrey }} />
              </IconButton>
            </Tooltip>
          </Box>

          <FormControl size="small">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={1} sx={{ color: 'primary.main' }}>
              <MenuItem value={1}>Data Feed - Level 1</MenuItem>
              <MenuItem value={2}>op2</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={10} sx={{ color: 'primary.main' }}>
              <MenuItem value={10}>Multi-Sector Report Long Quoted View</MenuItem>
              <MenuItem value={20}>seconds</MenuItem>
            </Select>
          </FormControl>
          <OpenCloseButton onClick={() => setSectionOpened(!sectionOpened)} opened={sectionOpened} />
        </Stack>
      </SectionHeader>
    )
  }
  return (
    <SectionContainer>
      <Header />
      <Collapse in={sectionOpened}>
        <Box p={3}>
          {subgroups.length === 0 && <>No sectors selected</>}
          <Grid container spacing={4} columns={{ sm: 12, md: 12, lg: 10, xl: 12 }}>
            {groupByCleanName.slice(0, 20).map((groupedApp, index) => {
              const applicantList = groupedApp.subgroups_data

              const tpd = thirdPartyDataList?.filter(tpd => tpd.applicant_cleaned === applicantList[0].applicant_cleaned)[0]

              return (
                <Grid size={{ xs: 6, sm: 6, md: 4, lg: 2, xl: 2 }} key={getKey(applicantList[0])}>
                  <ApplicantCard
                    applicantList={applicantList}
                    target={index === 0}
                    multisectorSubgroups={subgroups}
                    thirdPartyData={tpd}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Collapse>
    </SectionContainer>
  )
}
